import React from 'react';

export interface Props {
  logo: { name: string; logo: string; url: string; size?: 'small' | 'tiny' };
}

const PdlLogo = ({ logo }: Props) => {
  return (
    <a href={logo.url} target='_blank'>
      <img
        className={`pdl-logos__logo ${logo.size ? `pdl-logos__logo--${logo.size}` : ''}`}
        src={logo.logo}
        alt={`${logo.name} logo`}
      />
    </a>
  );
};

export default PdlLogo;
