import React, { useState } from 'react';
import classNames from 'classnames';
import { ReactJWPlayer } from 'player/ReactJWPlayer';
import { CDN_ASSETS_URL } from 'constants/global';
import { formatArtworkPrice, formatDuration } from 'services/stringFormating';
import * as Types from 'types';
import withSizes from 'react-sizes';
import { breakpoints } from 'services/theme';

interface Props {
  slide: Types.HomePageSlide;
  active: boolean;
  isMobile?: boolean;
  shouldPreload?: boolean;
  onFirstFrame: () => void;
}

const _ArtworkSlide = ({ slide, active, isMobile, shouldPreload, onFirstFrame }: Props) => {
  const [becameActive, setBecameActive] = useState<boolean>(false);
  const [isAboutToEnd, setIsAboutToEnd] = useState<boolean>(false);

  const videoTimeout = 2;

  if ((active || shouldPreload) && !becameActive) {
    setBecameActive(true);
  }

  const onTime = ({ position, duration }: jwplayer.TimeParam) => {
    if (position >= duration - videoTimeout && !isAboutToEnd) {
      setIsAboutToEnd(true);
    } else if (position <= videoTimeout && isAboutToEnd) {
      setIsAboutToEnd(false);
    }
  };

  const { artwork } = slide;

  const backgroundStyle = {
    background: '#000 no-repeat center center',
    backgroundImage: `url(${slide.artwork_image_url})`,
    backgroundSize: 'cover',
  };

  const styleContent = { color: slide.content_color };

  return (
    <div
      className={classNames('slide hero-slide hero-slide--artwork', {
        active,
      })}
      style={{
        display: 'block',
        zIndex: active ? 1 : 'auto',
        transition: 'opacity 1000ms ease 0s',
      }}
    >
      <div className='page-hero align-items-end image-container flex-row'>
        {!isMobile ? (
          <a
            href={artwork ? artwork.url : '#'}
            className='stretch w-100 h-100 zi-1 d-none d-sm-block'
          >
            {artwork && slide.artwork_video_url && artwork.type === 'video' ? (
              becameActive || shouldPreload ? (
                <div
                  className={classNames('stretch homepage-hero__video-background', {
                    'no-opacity': isAboutToEnd,
                  })}
                >
                  <ReactJWPlayer
                    url={slide.artwork_video_url}
                    onFirstFrame={onFirstFrame}
                    onTime={onTime}
                  />
                </div>
              ) : null
            ) : (
              <div className='lazy-background' style={backgroundStyle} />
            )}
          </a>
        ) : (
          <a
            href={artwork ? artwork.url : '#'}
            className='stretch w-100 h-100 zi-1 d-block d-sm-none'
          >
            <div className='lazy-background' style={backgroundStyle} />
          </a>
        )}
        {slide.show_overlay ? (
          <div>
            <div className='homepage-hero__shadow homepage-hero__shadow--top zi-1' />
            <div
              className='homepage-hero__shadow homepage-hero__shadow--bottom zi-1'
              style={{ height: '250px' }}
            />
          </div>
        ) : null}
        <div className='homepage-hero__content container-fluid px-gutter py-gutter zi-1'>
          <div className='row'>
            <div className='col homepage-hero__content-wrapper'>
              <a href={artwork ? artwork.url : '#'} className='d-block'>
                {slide.label ? slide.label : null}
                {artwork ? (
                  <h2
                    style={styleContent}
                    className='homepage-hero__artwork-title player__artwork-title mb-mini'
                  >
                    {artwork.title}
                  </h2>
                ) : null}
                {artwork ? (
                  <div
                    style={styleContent}
                    className='homepage-hero__author-name player__artist-name mb-1'
                  >
                    {artwork.artist_name}
                  </div>
                ) : null}
                {artwork ? (
                  <div
                    className='key-value key-value--strong key-value--hero white'
                    style={styleContent}
                  >
                    {`Digital Edition of ${artwork.total_editions}`}
                    {slide.artwork_video_url && artwork.duration
                      ? ` | ${formatDuration(artwork.duration)}`
                      : null}
                    {artwork.price ? ` | ${formatArtworkPrice(artwork.price)}` : null}
                  </div>
                ) : null}
              </a>
            </div>
            <div className='col d-none d-md-block'></div>
            <a
              href='/how-it-works'
              className='homepage-hero__displays col-auto d-none d-sm-flex flex-column align-items-end justify-content-end'
            >
              <span className='mb-mini d-block'>Display on</span>
              <img src={`${CDN_ASSETS_URL}homepage/displays.png`} style={{ width: '146px' }} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ArtworkSlide = withSizes<{}, Props>(({ width }) => ({
  width: width,
  isMobile: width <= breakpoints.sm,
}))(_ArtworkSlide);
