export const PLAYLIST_NAMES = {
  LG: 'LG Playlist',
  DISCOVER_WEEKLY: 'Discover Weekly',
  DISCOVER_DAILY: 'Discover Daily',
};

// Provide values for translation parser
('LG Playlist');
('Discover Weekly');
('Discover Daily');
('Master');
('Editions');
('Owned Edition');
