import React from 'react';
import classNames from 'classnames';
import * as Types from 'types';

import { useLoadImage } from 'hooks/useLoadImage';

interface Props {
  slide: Types.HomePageSlide;
  active: boolean;
}

export const CustomSlide = ({ slide, active }: Props) => {
  const contentInTheMiddle = slide.content_position === 'Middle';

  const isLoaded = useLoadImage(slide.image);

  return (
    <div
      className={classNames('slide hero-slide hero-slide--custom', { active })}
      style={{
        display: 'block',
        zIndex: active ? 1 : 'auto',
        transition: 'opacity 1000ms ease 0s',
      }}
    >
      <div
        className={classNames('page-hero align-items-end flex-row', {
          'align-items-sm-center': contentInTheMiddle,
        })}
      >
        <div
          className={`lazy-background ${isLoaded ? 'is-loaded' : ''}`}
          style={{
            backgroundColor: '#000',
            backgroundImage: isLoaded ? `url(${slide.image})` : undefined,
          }}
        />
        {slide.show_overlay ? (
          <div>
            <div className='homepage-hero__shadow homepage-hero__shadow--top zi-1' />
            <div
              className='homepage-hero__shadow homepage-hero__shadow--bottom zi-1'
              style={{ height: '250px' }}
            />
          </div>
        ) : null}
        <div
          className={classNames('homepage-hero__content container-fluid px-gutter py-gutter zi-1', {
            'px-sm-4': contentInTheMiddle,
          })}
          style={slide.background_color ? { background: slide.background_color } : {}}
        >
          <div className='homepage-hero__content-wrapper' style={{ color: slide.content_color }}>
            {slide.label ? <div className='homepage-hero__label mb-mini'>{slide.label}</div> : null}
            <h2 className='headline headline--medium mb-mini'>{slide.title}</h2>
            <p
              className='standfirst standfirst--small mb-1'
              dangerouslySetInnerHTML={{ __html: slide.subtitle }}
            />
            <a href={slide.link} className='btn btn--border-current-color mt-mini'>
              {slide.link_text || 'Learn More'}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
