import React, { useEffect } from 'react';

import * as Types from 'types';

import { RepeatMode } from '../utils';
import { useChromecast } from 'hooks/useChromecast';
const MAX_CHROMECAST_VOLUME = 99;

type Props = {
  index: number;
  playlist?: Types.Playlist;
  paused?: boolean;
  stopped: boolean;
  repeatMode: RepeatMode;
  // showTitle?: Types.ShowTitleOptions;
  // screenOrientation?: Types.ScreenOrientationOptions;
  volume: number;
  muted: boolean;
  onVolumeChanged: (volume: number) => void;
};

const numberOrUndefined = (value: string) =>
  Number.isNaN(parseInt(value, 10)) ? undefined : parseInt(value, 10);

export const ChromecastPlayer: React.FC<Props> = ({
  index,
  playlist,
  paused,
  stopped,
  repeatMode,
  volume,
  muted,
}) => {
  const { receiverPlaybackState, player } = useChromecast();

  useEffect(() => {
    if (!playlist || !player || stopped) {
      return;
    }

    const playlistId = numberOrUndefined(playlist.id);
    const playlistItemId = numberOrUndefined(playlist.items[index].id);

    if (playlist.items.length > 1) {
      playlistId !== undefined &&
        playlistItemId !== undefined &&
        player.playPlaylistById(playlistId, playlistItemId, repeatMode);
    } else if (playlist.items.length === 1) {
      player.playArtworkById(playlist.items[0].artwork.id);
    }
  }, [playlist?.id, stopped]);

  useEffect(() => {
    player?.getVolume() !== volume &&
      player?.setVolume(volume > MAX_CHROMECAST_VOLUME ? MAX_CHROMECAST_VOLUME : volume);
  }, [volume]);

  useEffect(() => {
    player?.setMute(muted);
  }, [muted]);

  useEffect(() => {
    if (paused !== undefined && receiverPlaybackState) {
      paused ? player?.pause() : player?.play();
    }
  }, [paused]);

  useEffect(() => {
    player?.playRepeatMode(repeatMode);
  }, [repeatMode]);

  return playlist ? (
    <div className='player__chromecast-player'>
      <div className='translate-into-middle'>
        <span className='d-block icon-cast-icon' />
        Casting
      </div>
    </div>
  ) : null;
};
