import React, { FC, useEffect, useRef } from 'react';
import classNames from 'classnames';
import animateScrollTo from 'animated-scroll-to';

import { orderListings } from 'apps/homepage/utils/orderListings';

import * as Types from 'types';
import { ActionFunctionAny } from 'redux-actions';
import { Action } from 'typesafe-actions';

type StateProps = {
  listings: Types.Listing[];
  currentListing: string;
};

type DispatchProps = {
  onMount: () => void;
  onClick: ActionFunctionAny<Action<any>>;
};

type OwnProps = {
  isSticky: boolean;
};

type Props = OwnProps & DispatchProps & StateProps;

export const Listings: FC<Props> = ({
  currentListing = null,
  onMount,
  isSticky,
  listings,
  onClick,
}) => {
  const scrollableContainer = useRef<HTMLDivElement | null>(null);

  useEffect(() => onMount(), []);

  const onTabClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    const top = $('.js-artwork-grid').offset()?.top;
    const target = event.target as HTMLElement;
    const { offsetLeft } = target;

    if (top && window.scrollY > top + 100) {
      $('html, body').animate(
        {
          scrollTop: top - 35,
        },
        200,
      );
    }

    if (scrollableContainer.current) {
      animateScrollTo([offsetLeft, null], {
        elementToScroll: scrollableContainer.current,
        speed: 300,
      });
    }

    onClick({ id });
  };

  return listings && listings.length > 0 ? (
    <div className='pos-rel'>
      <div className='artwork-grid__tabs-line' />
      <div className='artwork-grid__tabs w-100' ref={scrollableContainer}>
        <div
          className={classNames('artwork-grid__tabs-container d-flex justify-content-between', {
            'artwork-grid__tabs-container--sticky': isSticky,
          })}
        >
          {orderListings(listings).map(({ id, title }) => (
            <button
              className={classNames('artwork-grid__tab', {
                'artwork-grid__tab--active': currentListing === id,
                'artwork-grid__tab--sticky': isSticky,
              })}
              onClick={(event) => onTabClick(event, id)}
              key={id}
            >
              {title}
            </button>
          ))}
        </div>
      </div>
    </div>
  ) : null;
};
