import React from 'react';
import { Fade, Slide } from 'react-awesome-reveal';

type Props = {
  children: React.ReactNode;
  delay?: number;
  direction?: 'up' | 'down' | 'left' | 'right';
};

const FadeAndSlide = ({ delay = 0, direction = 'up', ...props }: Props) => {
  return (
    <Fade fraction={0.25} delay={delay + 600} triggerOnce style={{ opacity: 0 }}>
      <Slide fraction={0.25} delay={delay} triggerOnce direction={direction}>
        {props.children}
      </Slide>
    </Fade>
  );
};

export default FadeAndSlide;
