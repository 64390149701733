import { connect } from 'react-redux';

import { fetchListings, changeCurrentListing } from 'apps/shared/features/artworks/actions';
import { Listings } from './Component';

import { HomepageRootState } from 'apps/homepage/store';

const mapStateToProps = ({ artworks }: HomepageRootState) => ({
  listings: artworks.listings,
  currentListing: artworks.currentListing,
});

const mapDispatchToProps = {
  onMount: fetchListings,
  onClick: changeCurrentListing,
};

export const ListingsContainer = connect(mapStateToProps, mapDispatchToProps)(Listings);
