import React from 'react';
import classNames from 'classnames';

import { useFollowings } from 'features/followings/hooks/useFollowings';

type Props = {
  userId: number;
  className?: string;
};

export const FollowUserButton: React.FC<Props> = ({ userId, className }) => {
  const { isFollowing, follow, unfollow } = useFollowings(userId, 'User');

  return (
    <button
      onClick={(event) => {
        event.preventDefault();
        if (!window.sedition.loggedInUser) {
          window.location.href = Routes.signup_path({
            sticky_intent: 'follow_user',
            sticky_intent_id: userId,
          });
          return;
        }

        if (isFollowing) {
          unfollow(userId);
        } else {
          follow(userId);
        }
      }}
      className={classNames('btn btn--follow is-inactive', className, {
        'btn--bg-gray not-following': !isFollowing,
        'btn--bg-dark': isFollowing,
      })}
    >
      <span
        className={classNames({
          'icon-checkmark': isFollowing,
        })}
      />
      <span className='follow'>{isFollowing ? 'Following' : 'Follow'}</span>
    </button>
  );
};
