import { BigNumber, ethers, providers } from 'ethers';
import { sprintf } from 'sprintf';
import { chain } from 'wagmi';

import { getMetaTagValue } from 'utils/getMetaTagValue';
import { request } from 'utils/request';
import { WALLET_VERIFY_MESSAGE, OPEN_SEA_URL, CHAIN_ID, CONTRACT_ADDRESS } from 'constants/global';
import { errorFromException } from 'errors';

export type WaitForTransactionResult = providers.TransactionResponse;

export type TxnState = 'idle' | 'loading' | 'error' | 'pending' | 'success' | 'reverted';

const wagmiChainObject = Object.values(chain).find(({ id }) => id == CHAIN_ID);
if (!wagmiChainObject) {
  throw `Incorrectly congfigured CHAIN_ID ${CHAIN_ID}`;
}
export const CHAIN = wagmiChainObject;

export const web3Provider = window.ethereum?.isMetaMask
  ? new ethers.providers.Web3Provider(
      window.ethereum as unknown as ethers.providers.ExternalProvider,
    )
  : null;

type NftVouchersResponse = {
  signature: string;
  tokenId: string;
  issuerAddress: string;
};

export const getNftVoucher = async (chainId: number, editionId: number, address: string) => {
  try {
    const { data } = await request.post<NftVouchersResponse>(
      `/api/internal/editions/${editionId}/nft_vouchers`,
      {
        receiverAddress: address,
        chainId: Number(chainId),
      },
    );
    return { data, error: undefined };
  } catch (e) {
    return errorFromException(e, `Back-end was unable to return NFT voucher for ${editionId}`);
  }
};

export const getOpenSeaAddress = () => {
  return OPEN_SEA_URL;
};

export const encodeTokenID = (artworkId: number, editionNumber: number): BigNumber => {
  const bigArtworkId = ethers.BigNumber.from(artworkId);
  const bigEditionNumber = ethers.BigNumber.from(editionNumber);

  return bigArtworkId.shl(192).xor(bigEditionNumber);
};

export const getShortenedAddress = (
  address: string | undefined,
  style: 'address' | 'token' = 'address',
): string => {
  return style === 'address'
    ? address?.length
      ? address?.slice(0, 6) + '...' + address?.slice(-4)
      : ''
    : address?.length
    ? address?.slice(0, 12) + '...' + address?.slice(-12)
    : '';
};

export const generateMessageToSign = () => {
  const csrfToken = getMetaTagValue('csrf-token');
  return sprintf(WALLET_VERIFY_MESSAGE, csrfToken);
};

export const etherscanUrlForAddress = (address: string) => {
  const currentChain = Object.values(chain).find(({ id }) => id == CHAIN_ID);
  return currentChain && `//${currentChain.network}.etherscan.io/address/${address}`;
};

export const openSeaUrlForToken = (tokenId: string) => {
  let chainPrefix = '';
  let domain = 'opensea.io';

  if (CHAIN.testnet) {
    chainPrefix = `${CHAIN.network}/`;
    domain = 'testnets.opensea.io';
  }

  return `https://${domain}/assets/${chainPrefix}${CONTRACT_ADDRESS}/${tokenId}`;
};

export const etherscanUrlForTransaction = (txnHash: string) => {
  if (CHAIN_ID !== 1) {
    return `https://${CHAIN.network}.etherscan.io/tx/${txnHash}`;
  } else {
    return `https://etherscan.io/tx/${txnHash}`;
  }
};
