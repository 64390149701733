import React from 'react';

import { BuyForm } from 'components/artworkPreviewModal/BuyForm';
import { getArtworkEditionDescription } from 'artworks/helpers';
import { ComposingTooltip } from 'components/UI/ComposingTooltip';

import * as Types from 'types';
import { NftIcon } from 'components/icons/NftIcon';

const getNotAvailableLabel = (
  forSale: boolean,
  unavailableEditionsCount: number,
  auctionState?: Types.AuctionStates,
  isNFT?: boolean,
) => {
  switch (true) {
    case auctionState === Types.AuctionStates.upcoming:
      return 'Auction upcoming';
    case auctionState === Types.AuctionStates.ongoing:
      return 'Live auction';
    case !forSale && unavailableEditionsCount !== 0 && isNFT:
      return 'Sold Out';
    case !forSale && unavailableEditionsCount !== 0 && !isNFT:
      return 'Available on trade platform';
    default:
      return 'Sold out';
  }
};

type Props = {
  price: string;
  totalEditions: number;
  id: number;
  version: string;
  availableForPurchase: boolean;
  forSale: boolean;
  unavailableEditionsCount: number;
  soldOut: boolean;
  auctionState?: Types.AuctionStates;
  isNFT: boolean;
};

export const ArtworkPriceInfo: React.FC<Props> = ({
  price,
  totalEditions,
  id,
  version,
  availableForPurchase,
  forSale,
  unavailableEditionsCount,
  soldOut,
  auctionState,
  isNFT,
}) => {
  return (
    <div className='artwork-actions'>
      <b className='headline'>
        <div
          className='pr-mini'
          style={{
            paddingBottom: '8px',
            fontSize: availableForPurchase ? 'inherit' : '24px',
          }}
        >
          {availableForPurchase
            ? price
            : getNotAvailableLabel(forSale, unavailableEditionsCount, auctionState, isNFT)}
          {soldOut ? (
            <ComposingTooltip
              title='Sold out'
              className='artwork-component__sold-out ml-mini'
              style={{ marginBottom: '4px' }}
            />
          ) : null}
        </div>
      </b>
      {totalEditions > 0 && (
        <div
          className='key-value key-value__value d-flex align-items-center'
          style={{ paddingBottom: '8px' }}
        >
          {isNFT ? <NftIcon className='mr-1' /> : null}
          {getArtworkEditionDescription(totalEditions, isNFT)}
        </div>
      )}
      <BuyForm
        id={id}
        version={version}
        unavailableEditionsCount={unavailableEditionsCount}
        availableForPurchase={availableForPurchase}
        isNFT={isNFT}
      />
    </div>
  );
};
