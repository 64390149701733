import { useEffect, useState } from 'react';
import screenful from 'screenfull';

export const useFullscreen = () => {
  const s = screenful as screenful.Screenfull;
  const [isFullscreen, setIsFullscreen] = useState(
    s.isEnabled && s.isFullscreen,
  );

  const onFullscreenChange = () => {
    setIsFullscreen(s.isFullscreen);
  };

  useEffect(() => {
    if (s.isEnabled) {
      s.on('change', onFullscreenChange);
    }
    return () => {
      if (s.isEnabled) {
        s.off('change', onFullscreenChange);
      }
    };
  }, []);

  return {
    isEnabled: s.isEnabled,
    isFullscreen,
    toggle: (el: HTMLElement) => {
      if (s.isEnabled) {
        s.isFullscreen ? s.exit() : s.request(el);
      }
    },
  };
};
