const { currentUser } = window.sedition;

export const preloadedState = {
  wishList: {
    //@ts-ignore
    byId: currentUser.artworks ? currentUser?.artworks.wishes : {},
  },
  followers: {
    byId: currentUser.followed_users ? currentUser.followed_users : {},
  },
  currency: currentUser.currency,
  currentUser,
};
