import React, { FC } from 'react';

type Props = {
  content: string;
  url: string;
  linkText?: string;
};

export const GridTitle: FC<Props> = ({ content, url = null, linkText = 'Browse all artworks' }) => (
  <div className='d-flex justify-content-between align-items-center mb-1 mb-sm-1 pos-rel'>
    <h2 className='slate__title mb-0'>{content}</h2>
    {url && (
      <a className='slate__title-link link-border' href={url}>
        {linkText}
      </a>
    )}
  </div>
);
