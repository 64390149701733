import { ChromecastState, ActionKind } from './types';

export const reducer = (
  state: ChromecastState,
  { type, payload }: { type: ActionKind; payload: any },
): ChromecastState => {
  switch (type) {
    case ActionKind.UPDATE_DEVICE_STATE:
      return {
        ...state,
        available: payload,
      };
    case ActionKind.UPDATE_SESSION_STATE:
      return {
        ...state,
        connected: payload,
      };
    case ActionKind.UPDATE_CURRENT_ARTWORK:
      return {
        ...state,
        currentArtwork: payload,
      };
    case ActionKind.UPDATE_RECEIVER_PLAYER_STATE:
      return {
        ...state,
        receiverPlaybackState: payload,
      };
    case ActionKind.UPDATE_SETTINGS_PLAYER_STATE:
      return {
        ...state,
        settings: payload,
      };
    default:
      return state;
  }
};
