import React, { useState, useEffect, useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import * as Types from 'types';

import { useKeyPress } from 'hooks/useKeyPress';
import { useFullscreen } from 'hooks/useFullscreen';
import { useUserInputIdle } from 'hooks/useUserInputIdle';
import { utmParams } from 'hooks/utmParams';

import { ImagePlayer } from './ImagePlayer';
import { VideoPlayer } from './VideoPlayer';
import { ChromecastPlayer } from './ChromecastPlayer';
import { PlayerControl } from './PlayerControls';
import { Overlay } from './Overlay';
import { Preview } from './Preview';
import { Loader } from './Loader';

import { ensureMediaUrl } from '../ensureMediaUrl';
import { IDLE_DURATION, VOLUME } from '../config';
import { Quality, QualityLevel, qualityPreference } from '../quality';
import {
  repeatModePreference,
  MediaLoadState,
  RepeatMode,
  getNextRepeatMode,
  getShuffledIndex,
  volumePreference,
  getPlaylistCacheKey,
  DEFAULT_OPTIONS,
} from '../utils';

import { Options, PlayerState, PlaybackState } from '../types';
import { useChromecast } from 'hooks/useChromecast';

const MEDIA_URL_TTL = 60000; // 1 min in milliseconds

type Props = {
  playlist: Types.Playlist;
  index?: number;
  options?: Options;
  onIndexChanged?: (index: number) => void;
};

export const PlaylistPlayer: React.FC<Props> = ({
  index = 0,
  playlist,
  onIndexChanged,
  options: initialOption,
}) => {
  const options: Required<Options> = {
    ...DEFAULT_OPTIONS,
    ...initialOption,
  };

  const ref = React.createRef<HTMLDivElement>();
  const rightArrowDown = useKeyPress(['ArrowRight']);
  const leftArrowDown = useKeyPress(['ArrowLeft']);
  const spaceDown = useKeyPress([' ', 'Space']);

  const { connected, available, currentArtwork, player } = useChromecast();
  const fullscreenUtils = useFullscreen();
  const isIdle = useUserInputIdle(IDLE_DURATION);

  // Playback state: play -> pause
  const [state, setState] = useState<PlayerState>(options.autoplay ? 'loading' : 'stopped');

  const [playbackState, setPlaybackState] = useState<PlaybackState>('pause');
  const [isAutoPlayback, setAutoPlayback] = useState<boolean>(false);
  const [isVisible, setVisible] = useState<boolean>(false);
  const [nextIndex, setNextIndex] = useState<number>(index);

  // When quality is set to Auto this holds actual current quality level
  const [autoQuality, setAutoQuality] = useState<Quality>('HD');

  // User quality
  const [quality, setQuality] = useState(qualityPreference.get());

  // Repeat mode
  const [repeatMode, setRepeatMode] = useState(repeatModePreference.get());

  // Quality levels available in current video
  const [levels, setLevels] = useState<QualityLevel[] | null>(null);

  const [volume, setVolume] = useState(volumePreference.get());
  const [muted, setMuted] = useState<boolean>(!volumePreference.get());

  const artwork = playlist.items[index].artwork;

  const playlistCacheKey = getPlaylistCacheKey(playlist);
  const mediaURLCacheKey = `${index}${playlistCacheKey}`;

  const [mediaURLObject, setMediaURLObject] = useState<
    Record<
      string,
      | undefined
      | {
          url: string;
          expiresAt: number;
        }
    >
  >({});

  // So we render a Video or Image player and pass them mediaURL.
  // Our Playlist comes from props. Our mediaURL is in state.
  // When Playlist changes, we re-render and then set mediaURL to null
  // until it gets fetched. This cause an error because we try to
  // render with wrong old URL before setting it to null (eg. VideoPlayer
  // tries to render URL that points to an image). To prevent
  // that we use a cache key technique. Whenever Playlist changes
  // it generates different mediaURLCacheKey which in turn
  // return null. Rendering with null is OK.
  const mediaURL = mediaURLObject[mediaURLCacheKey];

  const playAtIndex = useCallback(
    (i: number) => {
      onIndexChanged?.(i);
      if (playbackState === 'pause' || state === 'stopped') {
        setPlaybackState('play');
        setState('playback');
      }
    },
    [playbackState, state, connected, playlist],
  );

  const next = (index: number) => {
    return index + 1 === playlist.items.length ? 0 : index + 1;
  };

  const previous = (index: number) => {
    return index - 1 < 0 ? playlist.items.length - 1 : index - 1;
  };

  const startTransition = () => {
    setState('stopped');
    fadeOutPlayer();
    setAutoPlayback(false);
  };

  const handleNext = useCallback(() => {
    if (connected) {
      return player?.playNextItem();
    }
    startTransition();
    setNextIndex(next);
  }, [index, connected]);

  const handlePrevious = useCallback(() => {
    startTransition();
    if (connected) {
      return player?.playPreviousItem();
    }
    fadeOutPlayer();
    setAutoPlayback(false);
    setNextIndex(previous);
  }, [index, connected]);

  const handleOnEnded = () => {
    if (options.single) {
      setPlaybackState('pause');
      setState('stopped');
    } else {
      switch (repeatMode) {
        case RepeatMode.REPEAT_ALL:
          setNextIndex(next);
          break;
        case RepeatMode.SHUFFLE:
          const newIndex = getShuffledIndex(playlist.items, index);
          newIndex !== undefined && setNextIndex(newIndex);
          break;
      }
      setAutoPlayback(true);
      fadeOutPlayer();
    }
  };

  const nextRepeatMode = () => {
    setRepeatMode((currentRepeatMode) => {
      const nextRepeatMode = getNextRepeatMode(currentRepeatMode);
      return nextRepeatMode;
    });
  };

  const onMediaLoadStateChanged = (state: MediaLoadState) => {
    if (state === 'loaded') {
      fadeInPlayer();
      setState(options.autoplay ? 'playback' : 'stopped');
    }
  };

  const handleError = (message: string) => {
    setState('error');
    bugsnag.notify({ message, mediaURL: mediaURL?.url, artwork, playlist });
    setMediaURLObject({ ...mediaURLObject, [mediaURLCacheKey]: undefined });
  };

  const fadeOutPlayer = useCallback(() => {
    setVisible(false);
  }, [isVisible]);

  const fadeInPlayer = useCallback(() => {
    setVisible(true);
  }, [isVisible]);

  const handleTransitionExited = useCallback(() => {
    playAtIndex(nextIndex);
  }, [nextIndex]);

  const toggleFullScreen = () => {
    if (ref && ref.current) {
      fullscreenUtils.toggle(ref.current);
    }
  };

  const triggerPlayBack = () => setPlaybackState(playbackState === 'play' ? 'pause' : 'play');

  const handlePlayerClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (event.currentTarget === event.target) {
      triggerPlayBack();
    }
  };

  const handleVolumeChange = useCallback(
    (vol: number) => {
      setVolume(vol);
      setMuted(!vol);
    },
    [volume],
  );

  const handleOnMute = useCallback(() => {
    setMuted(!muted);
    setVolume(!muted ? VOLUME.MIN : VOLUME.MAX);
  }, [volume]);

  /*================ EFFECTS ================ */

  useEffect(() => {
    if (options.autoplay) {
      playAtIndex(index);
    }
  }, []);

  useEffect(() => {
    if (connected && state === 'loading') {
      setState('stopped');
      setPlaybackState('pause');
    }
  }, [connected]);

  useEffect(() => {
    if (connected && options.autoplay) {
      playAtIndex(index);
      return;
    }

    if (!mediaURL || mediaURL.expiresAt <= Date.now()) {
      options.autoplay && setState('loading');

      ensureMediaUrl(artwork).then((url) => {
        setMediaURLObject({
          ...mediaURLObject,
          [mediaURLCacheKey]: {
            url,
            expiresAt: Date.now() + MEDIA_URL_TTL,
          },
        });
      });
    }
  }, [mediaURLCacheKey, connected]);

  useEffect(() => {
    volumePreference.store(volume);
  }, [volume]);

  useEffect(() => {
    repeatModePreference.store(repeatMode);
  }, [repeatMode]);

  useEffect(() => {
    rightArrowDown && handleNext();
    leftArrowDown && handlePrevious();
  }, [rightArrowDown, leftArrowDown]);

  useEffect(() => {
    qualityPreference.store(quality);
  }, [quality]);

  useEffect(() => {
    spaceDown && triggerPlayBack();
  }, [spaceDown]);

  const urlWithUtm = utmParams.set(artwork.url, {
    utm_campaign: 'embeddable_player',
    utm_content: 'start-collection',
    utm_medium: 'website',
    utm_source: 'embed',
  });

  return (
    <div
      ref={ref}
      className={classnames(`player player--${state} player--${artwork.type}`, {
        'player--pause': playbackState === 'pause',
        'player--play': playbackState === 'play',
        'player--playlist': playlist.items.length > 1,
        'player--casting': connected,
        'player--chromecast-available': available,
        'player--branding': options.branding,
        'player--purchase': options.purchase,
      })}
    >
      <div className='player__content'>
        <ErrorBoundary
          fallbackRender={({ error }) => {
            return (
              <div className='player__error'>
                <h1 className='player__label'>Error</h1>
                {error && <p>{error.message}</p>}
              </div>
            );
          }}
          onError={(error) => {
            handleError(error.message);
          }}
          onResetKeysChange={() => {
            setState('playback');
          }}
          resetKeys={[index]}
        >
          {connected ? (
            <ChromecastPlayer
              playlist={state !== 'stopped' ? playlist : undefined}
              index={index}
              paused={playbackState === 'pause'}
              stopped={state === 'stopped'}
              repeatMode={repeatMode}
              volume={volume}
              muted={muted}
              onVolumeChanged={setVolume}
            />
          ) : (
            <CSSTransition
              in={isVisible}
              timeout={400}
              classNames='media-players-transaction'
              onExited={handleTransitionExited}
            >
              <div className='player__media-players' onClick={handlePlayerClick}>
                {state !== 'error' && artwork.type === Types.ArtworkType.image && mediaURL && (
                  <ImagePlayer
                    url={mediaURL.url}
                    paused={playbackState === 'pause'}
                    onMediaLoadStateChange={onMediaLoadStateChanged}
                    onEnded={handleOnEnded}
                  />
                )}
                {state !== 'error' && artwork.type === Types.ArtworkType.video && mediaURL && (
                  <VideoPlayer
                    url={mediaURL.url}
                    paused={playbackState === 'pause'}
                    onMediaLoadStateChange={onMediaLoadStateChanged}
                    onAutoQualityChange={setAutoQuality}
                    onEnded={handleOnEnded}
                    onQualityLevels={setLevels}
                    quality={quality}
                    loop={
                      repeatMode === RepeatMode.REPEAT_ONE ||
                      (!options.single && playlist.items.length === 1)
                    }
                    volume={volume}
                    onVolumeChanged={setVolume} // Video player can change volume if autoplay with volume fails
                    mute={muted}
                    stopped={state === 'stopped'}
                  />
                )}
              </div>
            </CSSTransition>
          )}
        </ErrorBoundary>
        {!options.autoplay && !isAutoPlayback && (
          <Preview artwork={artwork} show={state === 'stopped'} />
        )}
        {(!isIdle || state === 'stopped' || playbackState === 'pause') && (
          <Overlay
            onCTAClick={() => playAtIndex(index)}
            onOverlayClick={handlePlayerClick}
            artwork={currentArtwork ? currentArtwork : artwork}
            options={options}
          />
        )}
        {!(isIdle && isAutoPlayback) && state === 'loading' && !connected && <Loader />}
        {(!isIdle || playbackState === 'pause') && (
          <PlayerControl
            playbackState={playbackState}
            next={handleNext}
            previous={handlePrevious}
            options={options}
            urlWithUtm={urlWithUtm}
            repeatMode={repeatMode}
            nextRepeatMode={nextRepeatMode}
            setRepeatMode={setRepeatMode}
            setPlaybackState={setPlaybackState}
            setQuality={setQuality}
            volume={muted ? VOLUME.MIN : volume}
            setVolume={handleVolumeChange}
            muted={muted}
            toggleMute={handleOnMute}
            chromecastState={{ connected }}
            levels={levels}
            quality={quality}
            autoQuality={autoQuality}
            handlePlayerClick={handlePlayerClick}
            toggleFullScreen={toggleFullScreen}
            isFullscreenEnabled={fullscreenUtils.isEnabled && !connected}
            isFullscreen={fullscreenUtils.isFullscreen}
          />
        )}
      </div>
    </div>
  );
};
