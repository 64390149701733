import React from 'react';
import ContentLoader from 'react-content-loader';
import truncate from 'truncate';

type Props = {
  description?: string;
};

/**
 * We are removing tags from description before truncating
 * to avoid un-closed HTML tags.
 */
export const ArtworkDescription: React.FC<Props> = ({ description }) => (
  <div className='mb-1 mb-md-0'>
    {description ? (
      <p className='text-content artwork-description mr-0 mr-md-2 pos-rel'>
        {truncate(description.replace(/(<([^>]+)>)/gi, ''), 210)}
      </p>
    ) : (
      <div style={{ marginBottom: '11px' }}>
        <ContentLoader
          height={49}
          width={400}
          speed={2}
          foregroundColor='#f3f3f3'
          backgroundColor='#ecebeb'
        >
          <rect x='0' y='5' rx='3' ry='3' width='350' height='6.4' />
          <rect x='0' y='23' rx='3' ry='3' width='380' height='6.4' />
          <rect x='0' y='41' rx='3' ry='3' width='201' height='6.4' />
        </ContentLoader>
      </div>
    )}
  </div>
);
