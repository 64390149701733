export const fetchMedia = function (artworkId: number): Promise<string> {
  return new Promise((resolve, reject) => {
    $.get('/artworks/' + artworkId + '/media.json')
      .then(function (response) {
        // Artworks that are not fully processed won't have adaptive source.
        // In that case use HD version of mp4 file.
        if (response.videos.adaptive || response.videos.hd) {
          const videoUrl: string =
            response.videos.adaptive || response.videos.hd;
          resolve(videoUrl);
        } else {
          resolve(response.images.hd as string);
        }
      })
      .catch(() => reject());
  });
};
