import { useEffect, useState, useRef } from 'react';

export const useTimeout = (delay: number) => {
  const [over, setOver] = useState<boolean>(false);

  useEffect(() => {
    if (delay !== null) {
      const id = setTimeout(() => setOver(true), delay);
      return () => clearTimeout(id);
    }
  }, [delay]);

  return over;
};
