import Cookies from 'js-cookie';
import sample from 'lodash/sample';
import debounce from 'lodash/debounce';
import orderBy from 'lodash/orderBy';

import * as Types from 'types';

export enum RepeatMode {
  REPEAT_ALL = 'REPEAT_ALL',
  REPEAT_ONE = 'REPEAT_ONE',
  SHUFFLE = 'SHUFFLE',
}

export const RepeatModes = [
  { mode: RepeatMode.REPEAT_ALL, label: 'Repeat All' },
  { mode: RepeatMode.REPEAT_ONE, label: 'Repeat One' },
  { mode: RepeatMode.SHUFFLE, label: 'Shuffle' },
];

export const getNextRepeatMode = (repeatMode: RepeatMode) => {
  const repeatModes = Object.keys(RepeatMode).filter(
    (x) => !(parseInt(x) >= 0),
  ) as unknown as RepeatMode[];
  const index = repeatModes.indexOf(repeatMode);
  const nextIndex = index + 1 === repeatModes.length ? 0 : index + 1;
  return repeatModes[nextIndex];
};

export const repeatModePreference = {
  get: (): RepeatMode => {
    const _repeatMode = Cookies.get('player-repeat');
    switch (_repeatMode) {
      case 'SHUFFLE':
        return RepeatMode.SHUFFLE;
      case 'REPEAT_ONE':
        return RepeatMode.REPEAT_ONE;
      default:
        return RepeatMode.REPEAT_ALL;
    }
  },
  store: (repeatMode: RepeatMode) => {
    Cookies.set('player-repeat', repeatMode);
  },
};

export const volumePreference = {
  get: () => {
    const volumeString = Cookies.get('player-volume');
    const parsed = parseInt(volumeString || '', 10);
    return Number.isNaN(parsed) ? 100 : parsed;
  },
  store: debounce(
    (volume: number) => {
      Cookies.set('player-volume', String(volume));
    },
    1000,
    { trailing: true },
  ),
};

export type PlayerOptions = {
  bgcolor?: string;
  autoplay: boolean;
  index?: number;
  controls?: boolean;
  purchase?: boolean;
  single?: boolean;
  branding?: boolean;
  fullscreen?: boolean;
  title?: string | null;
  loop?: boolean;
};

export const DEFAULT_OPTIONS: Required<PlayerOptions> = {
  bgcolor: 'black',
  autoplay: false,
  index: 0,
  controls: true,
  purchase: false,
  single: true,
  branding: false,
  fullscreen: true,
  title: null,
  loop: false,
};

export type MediaLoadState = 'idle' | 'loading' | 'loaded';

export type MediaPlayerProps = {
  url?: string;
  paused: boolean;
  onEnded?: () => void;
  onMediaLoadStateChange: (state: MediaLoadState) => void;
  onError?: (message: string) => void;
};

export const getShuffledIndex = (items: any[], currentIndex: number) => {
  if (items.length === 1) {
    return 0;
  }

  const all = Array(items.length)
    .fill(0)
    .map((_item, index) => index)
    .filter((item) => item !== currentIndex);

  return sample(all);
};

export const artworkToPlaylist = (artwork: Types.Artwork): Types.Playlist => {
  return {
    id: '',
    name: '',
    type: 'Regular',
    public: false,
    items: [
      {
        id: '',
        position: 0,
        artwork,
      },
    ],
  };
};

/**
 * We consider that playlist object has changed if
 * playlist ID or it's artwork arrangement has changed.
 */
export const getPlaylistCacheKey = (playlist: Types.Playlist) => {
  return `${playlist.id}${orderBy(playlist.items, (item) => -item.position).map(
    (item) => item.artwork.id,
  )}`;
};
