import { request } from 'utils/request';

import * as Types from 'types';

import { PlaylistResponse, mapPlaylistResponseToPlaylist } from './index';

export const requestUserPlaylists = async (
  userId: number,
  playlistType?: Types.PlaylistTypes,
): Promise<Types.Playlist[]> => {
  const { data } = await request.get<PlaylistResponse[]>(
    `/api/internal/users/${userId}/playlists.json`,
    {
      params: {
        playlist_type: playlistType,
      },
    },
  );
  return data.map(mapPlaylistResponseToPlaylist);
};
