import { useRef, useEffect, useCallback } from 'react';

type Options = {
  /**
   * How many ticks. One tick is 1 second by default
   * but can be changed using tickDurationMs property.
   */
  duration: number;
  /**
   * Tick duration in MS. Default: 1000.
   */
  tickDurationMs?: number;
  /**
   * Action to execute on complete.
   */
  onComplete?: () => void;
  /**
   * Action to execute on tick.
   */
  onTick?: () => void;
  /**
   * Start ticking on mount. Default: true.
   */
  startOnMount?: boolean;
};

type WithRequiredProperty<Type, Key extends keyof Type> = Type & {
  [Property in Key]-?: Type[Property];
};

const DEFAULT_OPTIONS: Required<Pick<Options, 'tickDurationMs' | 'startOnMount'>> = {
  tickDurationMs: 1000,
  startOnMount: true,
};

type Return = {
  pause: () => void;
  resume: () => void;
};

export const useInterval = (providedOptions: Options): Return => {
  const options: WithRequiredProperty<Options, 'tickDurationMs' | 'startOnMount'> = {
    ...DEFAULT_OPTIONS,
    ...providedOptions,
  };
  const intervalRef = useRef<number | null>(null);
  const internalTickRef = useRef(0);

  const cleanupInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const tick = () => {
    internalTickRef.current = internalTickRef.current + 1;
    options.onTick?.();
    if (internalTickRef.current >= options.duration) {
      cleanupInterval();
      options?.onComplete?.();
    }
  };

  useEffect(() => {
    if (options.startOnMount) {
      start();
    }
    return cleanupInterval;
  }, []);

  const start = (startFrom = 0) => {
    cleanupInterval();
    internalTickRef.current = startFrom;
    intervalRef.current = window.setInterval(tick, options.tickDurationMs);
  };

  const pause = () => {
    cleanupInterval();
  };

  const resume = () => {
    start(internalTickRef.current);
  };

  return {
    pause,
    resume,
  };
};
