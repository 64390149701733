import { action } from 'typesafe-actions';

import { request } from 'utils/request';
import * as Types from 'types';

import { PlaylistsActionTypes } from './types';
import { requestUserPlaylists, requestSavePlaylist, requestGetPlaylist } from './api';
import { newPlaylistArtwork, playlistFromArtworksArray } from './helpers';

export const setAddArtworkOpen = (open: boolean) =>
  action(PlaylistsActionTypes.SET_ADD_ARTWORK_OPEN, open);

export const setCurrentPlaylist = (playlist?: Types.Playlist) =>
  action(PlaylistsActionTypes.SET_CURRENT_PLAYLIST, playlist);

export const setPlaylists = (playlists: Types.Playlist[]) =>
  action(PlaylistsActionTypes.SET_PLAYLISTS, playlists);

export const resetPlaylists = (playlists: Types.Playlist[]) =>
  action(PlaylistsActionTypes.RESET_PLAYLISTS, playlists);

export const receivePlaylists = (playlists: Types.Playlist[]) =>
  action(PlaylistsActionTypes.RECEIVE_PLAYLISTS, playlists);

export const setArtStreamArtworksFilter = (filter: string) =>
  action(PlaylistsActionTypes.SET_ART_STREAM_ARTWORKS_FILTER, filter);

export const setArtStreamArtworksFetching = (isFetching: boolean) =>
  action(PlaylistsActionTypes.SET_ART_STREAM_ARTWORKS_FETCHING, isFetching);

export const removePlaylist = (playlistId: string) =>
  action(PlaylistsActionTypes.REMOVE_PLAYLIST, playlistId);

export const startFetching = () => action(PlaylistsActionTypes.START_FETCHING);

export const Actions = {
  startFetching,
  setAddArtworkOpen,
  setCurrentPlaylist,
  setPlaylists,
  resetPlaylists,
  receivePlaylists,
  setArtStreamArtworksFilter,
  removePlaylist,
  setArtStreamArtworksFetching,
};

export const fetchCurrentUserPlaylists =
  (): Types.DefaultThunkAction<Promise<void>> => (dispatch, getState) => {
    const {
      currentUser: { user },
    } = getState();

    if (!user) {
      return Promise.reject();
    }

    dispatch(startFetching());

    return requestUserPlaylists(user.id).then((playlists) => {
      dispatch(receivePlaylists(playlists));
    });
  };

export const fetchEditionsPlaylist = (): Types.DefaultThunkAction => (dispatch, getState) => {
  const {
    currentUser: { user },
  } = getState();

  if (!user) {
    return;
  }

  dispatch(startFetching());

  requestUserPlaylists(user.id, 'Editions').then((playlists) => {
    dispatch(receivePlaylists(playlists));
  });
};

export const destroyPlaylist =
  (playlistId: string): Types.DefaultThunkAction<Promise<void>> =>
  (dispatch) =>
    request.delete(`/playlists/${playlistId}.json`).then(() => {
      dispatch(removePlaylist(playlistId));
    });

export const savePlaylist =
  (playlist: Types.Playlist): Types.DefaultThunkAction<Promise<Types.Playlist>> =>
  async (dispatch) => {
    const receivedPlaylist = await requestSavePlaylist(playlist);

    dispatch(receivePlaylists([receivedPlaylist]));
    return receivedPlaylist;
  };

export const searchArtStreamArtworks =
  (query: string): Types.DefaultThunkAction =>
  (dispatch) => {
    dispatch(setArtStreamArtworksFilter(query));
  };

/**
 * Fetches given playlist, appends given artwork and saves to DB.
 * Updates state with newly saved playlist data.
 *
 * @param artwork
 * @param playlistId
 */
export const addArtworkToPlaylistAndSave =
  (artwork: Types.Artwork, playlistId: number): Types.DefaultThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    const { playlists } = getState();
    const playlist = playlists.byId[playlistId];

    const newArtwork = newPlaylistArtwork(artwork);

    const newPlaylist = await requestSavePlaylist({
      ...playlist,
      items: [...playlist.items, newArtwork],
    });

    dispatch(receivePlaylists([newPlaylist]));
  };

/**
 * Fetches given playlist, removes given artwork and saves to DB.
 * Updates state with newly saved playlist data.
 *
 * @param artwork
 * @param playlistId
 */
export const removeArtworkFromPlaylistAndSave =
  (artworkId: number, playlistId: number): Types.DefaultThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    const { playlists } = getState();

    const playlist = playlists?.byId[playlistId];

    const newPlaylist = await requestSavePlaylist({
      ...playlist,
      items: playlist.items.filter((item) => item.artwork.id !== artworkId),
    });

    dispatch(receivePlaylists([newPlaylist]));
  };

export const fetchPlaylistsByUser =
  (userId: number): Types.DefaultThunkAction<Promise<Types.Playlist[]>> =>
  async (dispatch) => {
    const playlists = await requestUserPlaylists(userId);
    dispatch(receivePlaylists(playlists));
    return playlists;
  };
