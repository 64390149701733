import React from 'react';

import { loadImage } from 'services/loadImage';

export const useLoadImage = (url?: string) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  React.useEffect(() => {
    setIsLoaded(false);
    if (url) {
      loadImage(url)
        .catch(console.error)
        .finally(() => {
          setIsLoaded(true);
        });
    }
  }, [url]);

  return isLoaded;
};
