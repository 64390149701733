import querystring from 'query-string';

type UTMParams = {
  utm_source: string; // The referred eg. google, newsletter
  utm_medium: string; // cpc, banner, email
  utm_campaign: string; // Campaign name eg. spring_sale
  utm_content?: string; // Use to differentiate tags
};

export const utmParams = {
  set: (url: string, params: UTMParams) => {
    const { query } = querystring.parseUrl(url);
    return url + '?' + querystring.stringify({ ...query, ...params });
  },
};
