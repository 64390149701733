import { ActionType } from 'typesafe-actions';

import { CurrentUserState, CurrentUserActionTypes } from './types';
import { Actions } from './actions';

export type CurrentUserAction = ActionType<typeof Actions>;

const INITIAL_STATE: CurrentUserState = {
  user: sedition.loggedInUser,
  followedUsers: [],
  followedPlaylists: [],
};

export const currentUserReducer = (
  state = INITIAL_STATE,
  action: CurrentUserAction,
): CurrentUserState => {
  switch (action.type) {
    case CurrentUserActionTypes.RECEIVE_CURRENT_USER:
      return {
        ...state,
        user: action.payload,
      };

    case CurrentUserActionTypes.RECEIVE_USER_FOLLOWINGS:
      action;
      return {
        ...state,
        followedUsers: action.payload,
      };

    case CurrentUserActionTypes.RECEIVE_PLAYLIST_FOLLOWINGS:
      return {
        ...state,
        followedPlaylists: action.payload,
      };

    default:
      return state;
  }
};
