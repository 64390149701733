import React from 'react';

import * as Types from 'types';

import { Icon } from 'components/icons/Icon';
import { ArtworkThumbnail } from './ArtworkThumbnail';
import { ArtworkThumbnailOverlay } from './ArtworkThumbnailOverlay';

type Props = {
  artwork: Types.Artwork;
  onPlay: (artworkId: number) => void;
} & Types.Styleable;

export const PlayableArtworkThumbnail = ({ onPlay, artwork, style, ...props }: Props) => (
  <ArtworkThumbnail
    artwork={artwork}
    onClick={() => onPlay(artwork.id)}
    style={{ cursor: 'pointer', ...style }}
    {...props}
  >
    <ArtworkThumbnailOverlay>
      <span className='player__btn player__btn--icon player__btn--icon-medium no-hover translate-into-middle'>
        <Icon icon='play' />
      </span>
    </ArtworkThumbnailOverlay>
  </ArtworkThumbnail>
);
