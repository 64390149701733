import { ChromecastActions } from 'types';
import { loadRemoteScript } from 'utils/helpers';

import { Emittable } from './Emittable';
import { RepeatMode } from './utils';

import { ChromecastSettings } from 'context/ChromecastContext/types';

// export let CUSTOM_CHANNEL: string;
// export let RECEIVER_ID: string;

// if (process.env.NODE_ENV === 'production') {
//   CUSTOM_CHANNEL = 'urn:x-cast:com.sedition.test';
//   RECEIVER_ID = 'A9290A1A';
// }

// if (process.env.NODE_ENV === 'staging') {
//   CUSTOM_CHANNEL = 'urn:x-cast:com.sedition.staging';
//   RECEIVER_ID = '884FDCA7';
// }

// if (process.env.NODE_ENV === 'development') {
//   CUSTOM_CHANNEL = 'urn:x-cast:com.sedition.dev';
//   RECEIVER_ID = '5DE3A41C';
// }

// export const CUSTOM_CHANNEL = 'urn:x-cast:com.sedition.dev';
// export const RECEIVER_ID = '5DE3A41C';

// export const CUSTOM_CHANNEL = 'urn:x-cast:com.sedition.staging';
// export const RECEIVER_ID = '884FDCA7';

export const CUSTOM_CHANNEL = 'urn:x-cast:com.sedition.test';
export const RECEIVER_ID = 'A9290A1A';

export type ChromecastControllerType = ChromecastController;

const getAccessToken = () => {
  return window?.sedition?.loggedInUser ? window.sedition.loggedInUser.accessToken : undefined;
};

class ChromecastController {
  _isLoaded = false;
  _isInitialized = false;
  _resolve!: (value?: ChromecastPlayer | PromiseLike<ChromecastPlayer>) => void;

  ready = new Promise<ChromecastPlayer>((resolve, reject) => {
    // @ts-ignore
    this._resolve = resolve;
  });

  player: ChromecastPlayer | null = null;

  castApiLoaded() {
    if (this._isLoaded) {
      return;
    }
    this._isLoaded = true;
    this.player = new ChromecastPlayer();
    this._resolve(this.player);
  }

  castApiAvailable() {
    if (this._isInitialized) {
      return;
    }
    this._isInitialized = true;

    // Setup cast framework
    const options = {
      receiverApplicationId: RECEIVER_ID,
      autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
    };
    cast.framework.CastContext.getInstance().setOptions(options);
    if (this.player) {
      this.player.setup();
    } else {
      // Cast API initialized before it was loaded.
      // Call to castApiLoaded() expected.
      throw 'This should not happen';
    }
  }
}

type ChromecastPlayerEvents = {
  connected: boolean;
  volumeChanged: number;
};

export class ChromecastPlayer extends Emittable<ChromecastPlayerEvents> {
  remotePlayer?: cast.framework.RemotePlayer;
  remotePlayerController?: cast.framework.RemotePlayerController;
  state: 'playing' | 'paused' = 'paused';
  isConnected = false;

  setup() {
    this.remotePlayer = new cast.framework.RemotePlayer();
    this.remotePlayerController = new cast.framework.RemotePlayerController(this.remotePlayer);

    this.remotePlayerController.addEventListener(
      cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
      (event) => {
        this.isConnected = event.value;
        this.emit('connected', event.value);
      },
    );

    this.remotePlayerController.addEventListener(
      cast.framework.RemotePlayerEventType.VOLUME_LEVEL_CHANGED,
      (event) => {
        this.emit('volumeChanged', Math.round(event.value * 100)); // 0-100
      },
    );

    this.remotePlayerController.addEventListener(
      cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED,
      (event) => {
        if (event.value === 'PLAYING') {
          this.state = 'playing';
        }
        if (event.value === 'PAUSED') {
          this.state = 'paused';
        }
      },
    );
  }

  pause() {
    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();

    castSession?.sendMessage(CUSTOM_CHANNEL, {
      type: 'PLAYLIST_PLAYBACK_PAUSE',
    });
  }

  play() {
    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();

    castSession?.sendMessage(CUSTOM_CHANNEL, {
      type: 'PLAYLIST_PLAYBACK_PLAY',
    });
  }

  setVolume(volume: number) {
    if (!this.remotePlayerController) {
      return;
    }
    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();
    if (castSession) {
      castSession.setVolume(volume / 100);
    }
  }

  setMute(mute: boolean) {
    if (!this.remotePlayerController) {
      return;
    }
    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();
    if (castSession) {
      castSession.setMute(mute);
    }
  }

  getVolume() {
    if (!this.remotePlayerController) {
      return;
    }
    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();
    if (castSession) {
      return Math.round(castSession.getVolume() * 100);
    }
  }

  playArtworkById(artworkId: number) {
    if (!this.remotePlayerController) {
      return;
    }
    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();

    if (!castSession) {
      bugsnag.notify(new Error('Unable to get cast session'));
      console.error('Unable to get cast session');
      // If that ever happens we want to disconnect from Chromecast
      return;
    }

    castSession.sendMessage(CUSTOM_CHANNEL, {
      action: 'PLAY_ARTWORK',
      artworkId,
      options: {
        accessToken: getAccessToken(),
      },
    });
  }

  playPlaylistById(playlistId: number, playlistItemId: number, repeatMode: RepeatMode) {
    if (!this.remotePlayerController) {
      return;
    }
    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();

    if (!castSession) {
      bugsnag.notify(new Error('Unable to get cast session'));
      console.error('Unable to get cast session');
      // If that ever happens we want to disconnect from Chromecast
      return;
    }

    const payload = {
      action: 'PLAY_PLAYLIST',
      playlistId,
      playlistItemId,
      options: {
        accessToken: getAccessToken(),
        repeatMode,
      },
    };

    castSession.sendMessage(CUSTOM_CHANNEL, payload);
  }

  playRepeatMode(repeatMode: RepeatMode) {
    if (!this.remotePlayerController) {
      return;
    }

    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();

    if (!castSession) {
      bugsnag.notify(new Error('Unable to get cast session'));
      console.error('Unable to get cast session');
      // If that ever happens we want to disconnect from Chromecast
      return;
    }

    castSession.sendMessage(CUSTOM_CHANNEL, {
      type: ChromecastActions.PLAYLIST_SET_REPEAT_MODE,
      payload: { repeatMode },
    });
  }

  setReceiverSettings(settings: ChromecastSettings) {
    if (!this.remotePlayerController) {
      return;
    }

    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();

    if (!castSession) {
      bugsnag.notify(new Error('Unable to get cast session'));
      console.error('Unable to get cast session');
      // If that ever happens we want to disconnect from Chromecast
      return;
    }

    castSession.sendMessage(CUSTOM_CHANNEL, {
      type: ChromecastActions.SET_RECEIVER_SETTINGS,
      payload: { settings },
    });
  }

  playNextItem() {
    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();

    if (!castSession) {
      bugsnag.notify(new Error('Unable to get cast session'));
      console.error('Unable to get cast session');
      // If that ever happens we want to disconnect from Chromecast
      return;
    }

    castSession.sendMessage(CUSTOM_CHANNEL, {
      type: ChromecastActions.PLAYLIST_TRANSITION_START,
      payload: { direction: 'next' },
    });
  }

  playPreviousItem() {
    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();

    if (!castSession) {
      bugsnag.notify(new Error('Unable to get cast session'));
      console.error('Unable to get cast session');
      // If that ever happens we want to disconnect from Chromecast
      return;
    }

    castSession.sendMessage(CUSTOM_CHANNEL, {
      type: ChromecastActions.PLAYLIST_TRANSITION_START,
      payload: { direction: 'previous' },
    });
  }

  setMuteState() {
    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();

    if (!castSession) {
      bugsnag.notify(new Error('Unable to get cast session'));
      console.error('Unable to get cast session');
      // If that ever happens we want to disconnect from Chromecast
      return;
    }

    castSession.sendMessage(CUSTOM_CHANNEL, {
      type: ChromecastActions.SET_MUTE_STATE,
    });
  }
}

const controller = new ChromecastController();
let loaded = false;

/**
 * Loads Google Cast API.
 * Returns ChromecastController instance.
 */
export const getChromecastInstance = (): ChromecastController => {
  if (!loaded) {
    loaded = true;
    window.__onGCastApiAvailable = function (isAvailable) {
      /**
       * Note isAvailable means "IS API AVAILABLE",
       * not "IS CHROMECAST AVAILABLE".
       *
       * To detect if Chromecast is available we listen for
       * <google-cast-launcher> mutations and when we detect
       * style property to change from 'none' to 'block' it means
       * Chromecast is available. maybe there is a better way.
       */
      controller.castApiLoaded();
      if (isAvailable) {
        // Initializing when isAvailable is false will fail
        // because window.chrome.cast namespace is not loaded
        controller.castApiAvailable();
      }
    };
    loadRemoteScript('https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1');
  }

  return controller;
};
