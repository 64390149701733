import uuidv4 from 'uuid/v4';
import { filter, sortBy } from 'lodash';

import * as Types from 'types';

import { PLAYLIST_NAMES } from './constants';

export const newPlaylist = (attributes: Partial<Types.Playlist> = {}): Types.Playlist => ({
  id: uuidv4(),
  name: 'New Playlist',
  items: [],
  type: 'Regular',
  public: false,
  ...attributes,
});

export const newPlaylistArtwork = (
  artwork: Types.Artwork,
  attributes: Partial<Types.DraggedPlaylistArtwork> = {},
) => {
  const playlistArtwork: Types.PlaylistArtwork = {
    artwork,
    position: 0,
    ...attributes,
    id: uuidv4(),
  };

  if (attributes._meta) {
    const draggable: Types.DraggedPlaylistArtwork = {
      ...playlistArtwork,
      _meta: attributes._meta,
    };
    return draggable;
  }

  return playlistArtwork;
};

export const playlistFromArtworksArray = (
  artworks: Types.Artwork[],
  playlistAttributes: Partial<Types.Playlist> = {},
): Types.Playlist => {
  const playlist = newPlaylist(playlistAttributes);
  const total = artworks.length;

  artworks.forEach((artwork, index) => {
    playlist.items.push(newPlaylistArtwork(artwork, { position: total - index }));
  });

  return playlist;
};

export const isPlaylistSaved = (playlist: Types.Playlist) => /^\d+$/.test(playlist.id);

export const filterSavedPlaylists = (playlists: Types.Playlist[]): Types.Playlist[] =>
  filter(playlists, isPlaylistSaved);

export const orderPlaylists = (
  playlists: Types.Playlist[],
  userId: number | undefined,
): Types.Playlist[] => {
  const master = playlists.find((i) => i.type === 'Master');
  const isMasterEmpty = !master?.items?.length;
  const isFollowedPlaylist = (playlist: Types.Playlist, userId: number | undefined) => {
    return playlist.userId && playlist.userId !== userId;
  };

  /**
   * The goal is to have:
   *
   * For users:
   *   Master / Discover playlists / Followed playlists / Custom playlists
   *
   * For subscribers with zero owned artworks:
   *   LG / Discover playlists / Followed playlists / Master / Custom playlists
   */
  return sortBy(playlists, (playlist) => {
    switch (playlist.type) {
      case 'Master':
        return isMasterEmpty ? 5 : 0;
      case 'Regular':
        if (isFollowedPlaylist(playlist, userId)) {
          // We should technically hardcode special IDs
          // here but eventually users should be able to
          // resort so this is just a temporary logic.
          switch (playlist.name) {
            case PLAYLIST_NAMES.LG:
              return 1;
            case PLAYLIST_NAMES.DISCOVER_WEEKLY:
              return 2;
            case PLAYLIST_NAMES.DISCOVER_DAILY:
              return 3;
            default:
              return 4;
          }
        } else {
          return playlist.id;
        }
    }
  });
};

export const getPlaylistCompatibilities = (
  playlist: Types.Playlist | undefined,
  userId: number,
): 'editable' | 'orderable' | 'followable' => {
  if (playlist?.type === 'Master') {
    return 'orderable';
  }

  if (playlist?.userId && playlist.userId !== userId) {
    return 'followable';
  }

  return 'editable';
};

export const isPlaylistEditable = (playlist: Types.Playlist | undefined, userId: number) => {
  if (playlist?.type === 'Master' || playlist?.type === 'Editions') {
    return false;
  }

  if (playlist && playlist.userId && playlist.userId !== userId) {
    return false;
  }

  return true;
};

export const translatedPlaylistName = (playlist: Types.Playlist) => {
  const shouldTranslate =
    playlist.type === 'Master' ||
    playlist.type === 'Editions' ||
    (playlist.public &&
      (playlist.name === PLAYLIST_NAMES.DISCOVER_DAILY ||
        playlist.name === PLAYLIST_NAMES.DISCOVER_WEEKLY ||
        playlist.name === PLAYLIST_NAMES.LG));

  if (shouldTranslate && playlist.name) {
    return playlist.name;
  } else {
    return playlist.name;
  }
};
