import React from 'react';
import classNames from 'classnames';

import * as Types from 'types';
import { ArtworkTitle } from 'components/artworks/ArtworkTitle';
import { ArtworkThumbnail } from 'components/artworks/ArtworkThumbnail';
import { PlayableArtworkThumbnail } from 'components/artworks/PlayableArtworkThumbnail';

import { shouldOpenNewTab } from 'components/utils/helper';

export const CLASS_NAME = 'artwork-component';

type Props = {
  artwork: Types.Artwork;
  showTitle?: boolean;
  url?: string;
  small?: boolean;
  dark?: boolean;
  thumbnailChildren?: React.ReactNode;
  meta?: React.ComponentType;
  titleLinks?: boolean;
  showArtistName?: boolean;
  maxlength?: number;
  showPrice?: boolean;
  playUI?: 'thumbnail' | 'artwork-title';
  onPlay?: (artworkId: number) => void;
  onThumbnailClick?: () => void;
  onTitleClick?: () => void;
} & Types.Styleable;

export const Artwork: React.FC<Props> = ({
  artwork,
  showTitle = true,
  playUI = 'artwork-title',
  children,
  ...props
}) => {
  let url = props.url;
  // Note props.url = null removes the link, this is desired behaviour.
  if (url === undefined && artwork) {
    url = artwork.url;
  }

  let thumbnail;
  if (playUI === 'thumbnail' && props.onPlay && artwork.childrenCount === 0) {
    thumbnail = <PlayableArtworkThumbnail artwork={artwork} onPlay={props.onPlay} />;
  } else {
    thumbnail = (
      <div className='artwork-component'>
        <ArtworkThumbnail url={url} artwork={artwork} onClick={props.onThumbnailClick}>
          {props.thumbnailChildren}
        </ArtworkThumbnail>
        <a
          href={url}
          className='artwork-component__overlay'
          target={shouldOpenNewTab() ? '_blank' : undefined}
        ></a>
      </div>
    );
  }

  return (
    <div className={classNames(CLASS_NAME, props.className)} style={props.style}>
      <div className='artwork-component__thumbnail-wrapper'>
        {thumbnail}
        {props.meta}
        {children}
      </div>
      {showTitle && (
        <ArtworkTitle
          titleLinks={props.titleLinks}
          url={url}
          artwork={artwork}
          onPlay={playUI === 'artwork-title' ? props.onPlay : undefined}
          onClick={props.onTitleClick}
          maxlength={props.maxlength}
          showArtistName={props.showArtistName}
          small={props.small}
          dark={props.dark}
          secondaryColumn={props.showPrice}
        />
      )}
    </div>
  );
};

export { ArtworkThumbnail } from 'components/artworks/ArtworkThumbnail';
