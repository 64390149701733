import React, { FC } from 'react';
import classnames from 'classnames';
import Slider from 'react-rangeslider';
import { uniqBy } from 'lodash';

import { RepeatModes, RepeatMode } from 'artworkPlayer/utils';
import { qualityToLongLabel, QualityLevel, Quality } from 'artworkPlayer/quality';
import { ControlWithOptions } from './ControlWithOptions';
import { GoogleCastLauncher } from './GoogleCastLauncher';

import { PlaybackState, Options } from 'artworkPlayer/types';

import { ChromecastState } from 'context/ChromecastContext/types';
import { Settings } from './Settings';

import * as Types from 'types';

type Props = {
  playbackState: PlaybackState;
  previous: () => void;
  next: () => void;
  options: Options;
  urlWithUtm: string;
  repeatMode: RepeatMode;
  nextRepeatMode: () => void;
  setRepeatMode: React.Dispatch<React.SetStateAction<RepeatMode>>;
  setPlaybackState: React.Dispatch<React.SetStateAction<PlaybackState>>;
  setVolume: (vol: number) => void;
  setQuality: React.Dispatch<React.SetStateAction<Quality>>;
  volume: number;
  chromecastState?: ChromecastState;
  levels: QualityLevel[] | null;
  quality: Quality;
  autoQuality: Quality;
  handlePlayerClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  toggleMute: () => void;
  muted: boolean;
  toggleFullScreen: () => void;
  isFullscreenEnabled: boolean;
  isFullscreen: boolean;
};

export const PlayerControl: FC<Props> = ({
  playbackState,
  previous,
  next,
  options,
  urlWithUtm,
  repeatMode,
  nextRepeatMode,
  setRepeatMode,
  setPlaybackState,
  setVolume,
  setQuality,
  volume,
  muted,
  chromecastState,
  levels,
  quality,
  autoQuality,
  handlePlayerClick,
  toggleMute,
  toggleFullScreen,
  isFullscreenEnabled,
  isFullscreen,
}) => (
  <div className='player__controls'>
    <div className='player__controls__left'>
      <a
        className={`player__btn player__btn--icon player__btn--play-pause icon-${
          playbackState === 'play' ? 'pause' : 'play'
        }`}
        onClickCapture={() => {
          setPlaybackState(playbackState === 'play' ? 'pause' : 'play');
        }}
      />
      <a
        className='player__btn player__btn--icon player__btn--previous icon-previous'
        onClick={previous}
      />
      <a className='player__btn player__btn--icon player__btn--next icon-next' onClick={next} />
      {options.purchase ? (
        <a className='player__btn player__btn--guest-cta' target='_blank' href={urlWithUtm}>
          Buy
        </a>
      ) : null}
      {options.purchase ? (
        <a
          className='player__btn player__btn--text player__btn--guest-cta'
          target='_blank'
          href={urlWithUtm}
        >
          Start your digital artwork
          <br />
          collection on
          {'\u00A0'}
          <span style={{ textDecoration: 'underline' }}>seditionart.com</span>
        </a>
      ) : null}
    </div>
    <div className='player__controls__right'>
      <GoogleCastLauncher className='player__btn player__btn--icon player__btn--cast-wrapper' />
      {chromecastState?.connected && <Settings />}
      <ControlWithOptions
        optionsElement={() => (
          <div className='player__btn-options__wrapper'>
            <div className='player__btn-options player__btn-options--select'>
              {RepeatModes.map((availableRepeatMode) => {
                return (
                  <div
                    className={classnames('player__btn-options__option', {
                      'player__btn-options__option--selected':
                        availableRepeatMode.mode === repeatMode,
                    })}
                    key={availableRepeatMode.mode}
                    onClick={(event) => {
                      event.stopPropagation();
                      setRepeatMode(availableRepeatMode.mode);
                    }}
                  >
                    {availableRepeatMode.label}
                  </div>
                );
              })}
            </div>
          </div>
        )}
        buttonElement={() => (
          <div
            className={classnames(
              'player__btn player__btn--icon player__btn--repeat-mode player__btn--with-options',
              {
                'icon-repeat-all': repeatMode === 'REPEAT_ALL',
                'icon-repeat-one': repeatMode === 'REPEAT_ONE',
                'icon-shuffle': repeatMode === 'SHUFFLE',
              },
            )}
            onClick={nextRepeatMode}
          />
        )}
      />
      {!chromecastState?.connected && levels && levels.length > 1 && (
        <ControlWithOptions
          optionsElement={() => (
            <div className='player__btn-options__wrapper'>
              <div className='player__btn-options player__btn-options--select'>
                {chromecastState?.connected ? (
                  <div>Not supported while casting</div>
                ) : (
                  levels &&
                  uniqBy<QualityLevel>(levels, (level) => {
                    // We offer users to choose level based on resolution
                    // sometimes we have two levels with same resolution,
                    // but different bitrates. In that case we show only one.
                    return level.label;
                  }).map((qualityLevel) => (
                    <div
                      key={qualityLevel.label}
                      className={classnames('player__btn-options__option', {
                        'player__btn-options__option--selected': quality === qualityLevel.label,
                      })}
                      onClick={() => setQuality(qualityLevel.label)}
                    >
                      {qualityToLongLabel(qualityLevel.label)}
                    </div>
                  ))
                )}
              </div>
            </div>
          )}
          buttonElement={() => (
            <div className='player__btn player__btn--icon player__btn--quality player__btn--with-options'>
              <div className='player__btn-content' onClick={handlePlayerClick}>
                {
                  chromecastState?.connected
                    ? 'HD' // Always HD when Chromecast is connected
                    : quality === 'Auto'
                    ? autoQuality // Current auto-quality
                    : quality // Current user quality
                }
              </div>
            </div>
          )}
        />
      )}
      <ControlWithOptions
        optionsElement={() => (
          <div className='player__btn-options__wrapper'>
            <div className='player__btn-options player__btn-options--volume'>
              <Slider
                className='player__volume-slider'
                min={0}
                max={100}
                value={volume}
                orientation='vertical'
                onChange={setVolume}
              />
            </div>
          </div>
        )}
        buttonElement={() => (
          <div
            onClickCapture={toggleMute}
            role='button'
            className={classnames(
              'player__btn player__btn--volume player__btn--icon player__btn--with-options',
              {
                'icon-volume-high': !muted,
                'icon-volume-mute': muted,
              },
            )}
          />
        )}
      />
      {isFullscreenEnabled && (
        <a
          className={`player__btn player__btn--icon player__btn--fullscreen icon-fullscreen${
            isFullscreen ? '-exit' : ''
          }`}
          onClick={toggleFullScreen}
        />
      )}
    </div>
  </div>
);
