import React, { FC } from 'react';

type Props = {
  label: string;
  onClick: () => void;
};

export const GridButton: FC<Props> = ({ onClick, label }) => (
  <button onClick={() => onClick()} className='btn btn--bg-gray pb-1 pt-1 pr-3 pl-3'>
    {label}
  </button>
);
