import React from 'react';
import classNames from 'classnames';

import { AnimatedCard } from 'apps/discover/components/AnimatedCard';
import { WishListButton } from 'apps/discover/components/wishlist/WishListButton';
import { ComposingTooltip } from 'components/UI/ComposingTooltip';

import * as Types from 'types';
import { NftIconWithTooltip } from 'components/icons/NftIcon';

type Props = {
  id: number;
  thumbnail: string;
  title?: string;
  url: string;
  artistUrl?: string;
  currentPrice?: string;
  artistName?: string;
  forSale?: boolean;
  soldOut?: boolean;
  className?: string;
  multipleIterations?: boolean;
  hideWishList?: boolean;
  auctionState?: Types.AuctionStates;
  isMintable?: boolean;
  onClickPreview?: (artworkId: number) => void;
  onMount?: (ref: React.RefObject<HTMLAnchorElement>) => void;
};

export const Artwork: React.FC<Props> = (props) => {
  const ref = React.createRef<HTMLAnchorElement>();
  React.useEffect(() => {
    props.onMount ? props.onMount(ref) : undefined;
  }, [ref.current, props.onMount]);

  return (
    <AnimatedCard
      className={classNames(
        'artwork artwork-component mb-gutter',
        props.className ? props.className : 'col-12 col-lg-4 col-md-4 col-sm-6',
      )}
    >
      <div className='artwork-component'>
        <a ref={ref} className='artwork-component__thumbnail bg-black pos-rel' href={props.url}>
          <img
            alt={props.title}
            className='artwork-component__thumbnail-image is-loaded'
            src={props.thumbnail}
          />
          {!props.hideWishList && <WishListButton artworkId={props.id} />}
        </a>
        <a href={props.url} className='artwork-component__overlay'></a>
      </div>
      {props.title && (
        <div className='row artwork-component__content'>
          <figcaption className='col artwork-header artwork-header--sm'>
            <h1 className='artwork-header__title'>
              <a href={props.url}>{props.title}</a>
            </h1>
            <h2 className='artwork-header__name'>
              <a href={props.artistUrl}>{props.artistName}</a>
            </h2>
          </figcaption>
          <div className='col-auto artwork--align-right'>
            {props.multipleIterations || props.soldOut ? (
              <div className='d-flex justify-content-end align-items-center'>
                {props.multipleIterations && (
                  <ComposingTooltip
                    title='Multiple Iterations'
                    className='icon-layers vat d-inline-block fs-16'
                  />
                )}
                {props.soldOut && (
                  <ComposingTooltip
                    title='Sold out'
                    className='artwork-component__sold-out'
                    style={{ marginBottom: '4px' }}
                  />
                )}
              </div>
            ) : null}
            {!props.soldOut ? (
              !props.multipleIterations ? (
                <a className='artwork-header__secondary-info-text' href={props.url}>
                  {props.forSale && props.currentPrice
                    ? props.currentPrice
                    : props.auctionState
                    ? 'Auction'
                    : ''}
                </a>
              ) : null
            ) : null}
            {props.isMintable ? <NftIconWithTooltip /> : null}
          </div>
        </div>
      )}
    </AnimatedCard>
  );
};
