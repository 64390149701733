import { createAction } from 'redux-actions';

import { request } from 'utils/request';
import { endpoints } from 'apps/shared/features/wishList/endpoints';
import { WISHLIST_ADD, WISHLIST_REMOVE } from 'apps/shared/features/wishList/constants';
import { HomepageRootState } from 'apps/homepage/store';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

export const addToWishList =
  (artworkId: number): ThunkAction<void, HomepageRootState, unknown, AnyAction> =>
  (dispatch) => {
    request.post(endpoints.wishes, { artwork_id: artworkId }).then((res) => {
      if (res) {
        dispatch(createAction(WISHLIST_ADD)({ wishId: res.data.id, artworkId }));
      }
    });
  };

export const removeFromWishList =
  (wishId: number, artworkId: number): ThunkAction<void, HomepageRootState, unknown, AnyAction> =>
  (dispatch) => {
    request.delete(`${endpoints.wishes}/${wishId}`).then((res) => {
      if (res) {
        dispatch(createAction(WISHLIST_REMOVE)({ artworkId }));
      }
    });
  };
