import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import thunk from 'redux-thunk';

import { trackingMiddleware } from 'utils/tracking/trackingMiddleware';
import { preloadedState } from 'apps/shared/preLoadedState';

import { artworksReducer } from 'apps/shared/features/artworks/reducer';
import { currencyReducer } from 'apps/shared/features/currency/reducer';
import { modalReducer } from 'apps/shared/features/modal/reducer';
import { wishListReducer } from 'apps/shared/features/wishList/reducer';
import { playlistsReducer } from 'features/playlists/reducer';
import { currentUserReducer } from 'features/currentUser/reducer';
import { mediaReducer } from 'features/media/reducer';

const composeEnhancers = composeWithDevTools({});

export const store = createStore(
  combineReducers({
    artworks: artworksReducer,
    currency: currencyReducer,
    modal: modalReducer,
    wishList: wishListReducer,
    playlists: playlistsReducer,
    currentUser: currentUserReducer,
    media: mediaReducer,
  }),
  {
    currency: preloadedState.currency,
    currentUser: {
      user: sedition.loggedInUser,
      followedUsers: sedition.loggedInUser ? sedition.loggedInUser.followedUsers || [] : [],
      followedPlaylists: sedition.loggedInUser ? sedition.loggedInUser.followedPlaylists || [] : [],
    },
  },
  composeEnhancers(applyMiddleware(thunk, trackingMiddleware)),
);

export type HomepageRootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
