import { request } from 'utils/request';

import * as Types from 'types';

export const requestFollowings = (
  userId: number,
  type: Types.FollowableType,
) => {
  return request.get(`/api/internal/users/${userId}/followings`, {
    params: {
      followable_type: type,
    },
  });
};

export const requestFollow = (
  userId: number,
  followableId: number,
  type: Types.FollowableType,
) => {
  return request.post(`/api/internal/users/${userId}/followings`, {
    following: { followable_id: followableId, followable_type: type },
  });
};

export const requestUnfollow = (
  userId: number,
  followableId: number,
  type: Types.FollowableType,
) => {
  return request.delete(`/api/internal/users/${userId}/followings`, {
    params: {
      followable_id: followableId,
      followable_type: type,
    },
  });
};
