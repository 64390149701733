import * as Types from 'types';
import { ResponseArtwork } from 'features/artworks/api';
import { mapResponseArtworkToArtwork } from 'features/artworks/api';

type PlaylistItemResponse = {
  id: number;
  position: number;
  artwork: ResponseArtwork;
  edition_id?: number;
};

export type PlaylistResponse = {
  id: number;
  name: string;
  type: 'Regular' | 'Master' | 'Editions';
  items: PlaylistItemResponse[];
  created_at: number;
  public: boolean;
  description?: string;
  user_id: number;
};

const mapPlaylistItemResponseToPlaylistItem = (
  response: PlaylistItemResponse,
): Types.PlaylistArtwork => ({
  id: String(response.id),
  position: response.position,
  artwork: mapResponseArtworkToArtwork(response.artwork),
  editionId: response.edition_id,
});

export const mapPlaylistResponseToPlaylist = (
  response: PlaylistResponse,
): Types.Playlist => ({
  id: String(response.id),
  name: response.name,
  items: response.items.map(mapPlaylistItemResponseToPlaylistItem),
  type: response.type,
  public: response.public,
  description: response.description,
  userId: response.user_id,
});

export { requestSavePlaylist } from './requestSavePlaylist';
export { requestGetPlaylist } from './requestGetPlaylist';
export { requestUserPlaylists } from './requestUserPlaylists';
export { requestPublicPlaylists } from './requestPublicPlaylists';
