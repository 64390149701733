import React, { FC } from 'react';
import { Options } from '../types';

import { formatArtworkPrice, formatDuration } from 'services/stringFormating';
import { sprintf } from 'sprintf';

import * as Types from 'types';

type Props = {
  onOverlayClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onCTAClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  artwork: Types.Artwork | Types.ArtworkDetails;
  options: Options;
};

export const Overlay: FC<Props> = ({ onOverlayClick, onCTAClick, artwork, options }) => (
  <div className='player__overlay' onClick={onOverlayClick}>
    <div className='player__branding'>
      <div className='player__branding__logo' />
      <div className='player__label--small'>Limited Digital Editions</div>
    </div>
    <div className='player__artwork-info'>
      <a href={artwork.url} className='player__artwork-title mb-mini d-block float-left clearfix'>
        {artwork.title}
      </a>
      <a href={artwork.url} className='player__artist-name d-block float-left clearfix'>
        {artwork.artistName}
      </a>
      {options.purchase ? (
        <ul className='player__breadcrumb'>
          <li>{formatArtworkPrice(artwork.price)}</li>
          <li>{sprintf('%s editions', artwork.totalEditions)}</li>
        </ul>
      ) : null}
    </div>
    <div className='player__cta zi-1' onClick={onCTAClick}>
      <a
        className={`player__btn player__btn--icon player__btn--large icon-${
          artwork.type === 'video' ? 'play' : 'zoom'
        }`}
      />
      <ul className='player__breadcrumb d-none d-sm-block'>
        <li>{artwork.type === 'video' ? 'Play Artwork' : 'View Artwork'}</li>
        {Types.isArtworkWithDetails(artwork) && artwork.duration ? (
          <li>{formatDuration(artwork.duration)}</li>
        ) : null}
      </ul>
    </div>
  </div>
);
