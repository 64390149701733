import React, { FC } from 'react';

interface WithLoopProps {
  id?: string;
  duration: number;
  distance: number;
}

const WithLoop: FC<WithLoopProps> = ({ children, id, distance, duration }) => {
  const animation = `
    @keyframes loop${id ? `-${id}` : ''} {
      from  { transform: translate3d(0, 0, 0); }
      to  { transform: translate3d(${-1 * distance}px, 0, 0); }
    }
    `;

  return (
    <>
      <style children={animation} />
      <div
        className='animated-carousel--pause'
        style={{
          animationDuration: `${duration}s`,
          animationIterationCount: 'infinite',
          animationName: `loop${id ? `-${id}` : ''}`,
          animationTimingFunction: 'linear',
        }}
      >
        {children}
      </div>
    </>
  );
};

export default WithLoop;
