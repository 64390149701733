import React from 'react';

type ChildrenProps = {
  close: () => void;
  open: () => void;
  trigger: () => void;
  isOpen: boolean;
};

type Props = {
  children?: (props: ChildrenProps) => any;
};

type State = {
  isOpen: boolean;
};

export class CollapsableContent extends React.PureComponent<Props, State> {
  ref: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.trigger = this.trigger.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick);
  }

  handleOutsideClick(event: DocumentEventMap['mousedown']) {
    if (
      this.ref &&
      this.ref.current &&
      !this.ref.current.contains(event.target as Node)
    ) {
      this.close();
    }
  }

  close() {
    this.setState({ isOpen: false });
  }

  open() {
    this.setState({ isOpen: true });
  }

  trigger() {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  }

  render() {
    const { children } = this.props;

    return (
      <div ref={this.ref}>
        {children &&
          children({
            open: this.open,
            close: this.close,
            trigger: this.trigger,
            isOpen: this.state.isOpen,
          })}
      </div>
    );
  }
}
