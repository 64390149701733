import React, { FC } from 'react';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import { useLoadImage } from 'hooks/useLoadImage';

import * as Types from 'types';

type Props = {
  artwork: Types.Artwork;
  show: boolean;
};

export const Preview: FC<Props> = ({ artwork, show }) => {
  const isPreviewImageLoaded = useLoadImage(artwork.thumbnailUrl);

  return (
    <CSSTransition
      in={show}
      timeout={500}
      classNames='css-transition--opacity'
      unmountOnExit
    >
      <div
        className={classnames('player__preview', {
          'player__preview--loaded': isPreviewImageLoaded,
        })}
        style={{
          backgroundImage: isPreviewImageLoaded
            ? `url(${artwork.thumbnailUrl})`
            : undefined,
        }}
      />
    </CSSTransition>
  );
};
