import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { showAuthenticationModal } from 'services/showAuthenticationModal';
import { ComposingTooltip } from 'components/UI/ComposingTooltip';

import {
  addToWishList,
  removeFromWishList,
  // @ts-ignore
} from 'apps/shared/features/wishList/actions';

type Props = {
  isActive: boolean;
  wishId?: number;
  bordered?: boolean;
  alwaysVisible?: boolean;
  onAdd: () => void;
  onRemove: (wishId: number) => void;
};

const WishListButtonComponent: React.FC<Props> = ({
  bordered,
  isActive,
  wishId,
  onAdd,
  onRemove,
  alwaysVisible,
}) => (
  <ComposingTooltip
    title={isActive ? 'Remove from wish list' : 'Add to wish list'}
    className={classNames('card-action action-icon', {
      'is-inactive': !isActive,
      'is-active': isActive,
      'card-action--bordered': bordered,
      'd-flex': bordered,
      'justify-content-center': bordered,
      'align-items-center': bordered,
    })}
    style={alwaysVisible ? { opacity: 1 } : {}}
  >
    <span
      onClick={() => {
        if (isActive && wishId) {
          onRemove(wishId);
        } else {
          onAdd();
        }
      }}
    >
      <div className='action-icon__glymph action-icon__glymph--alt icon-heart' />
    </span>
  </ComposingTooltip>
);

// @ts-ignore
const mapStateToProps = ({ wishList }, { artworkId }) => {
  const wishIds = Object.keys(wishList.byId);
  const isActive = wishIds.indexOf(String(artworkId)) >= 0;

  let wishId;

  if (isActive) {
    wishId = wishList.byId[artworkId];
  }

  return {
    isActive,
    wishId,
  };
};

// @ts-ignore
const mapDispatchToProps = (dispatch, { artworkId }) => ({
  onAdd: () => {
    if (sedition.currentUser.id) {
      dispatch(addToWishList(artworkId));
    } else {
      showAuthenticationModal('signup');
    }
  },
  onRemove: (wishId: number) => {
    dispatch(removeFromWishList(wishId, artworkId));
  },
});

export const WishListButton = connect(mapStateToProps, mapDispatchToProps)(WishListButtonComponent);
