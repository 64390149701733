import React from 'react';
import LazyLoad from 'react-lazyload';

import { CDN_ASSETS_URL } from 'constants/global';

type Props = {
  fileName: string;
  poster?: string;
};

const PdlVideo = (props: Props) => {
  return (
    <LazyLoad classNamePrefix='public-displays__lazy-load' offset={200}>
      <div
        dangerouslySetInnerHTML={{
          __html: `<video
          class='public-displays__video'
          autoplay
          loop
          muted
          playsinline
          poster=${props.poster ? `${CDN_ASSETS_URL}public-displays/${props.poster}` : ''}
        >
          <source src=${CDN_ASSETS_URL}public-displays/${props.fileName} type='video/mp4' />
        </video>`,
        }}
      />
    </LazyLoad>
  );
};

export default PdlVideo;
