import { request } from 'utils/request';

import {
  ResponseLoggedInUser,
  mapResponseUserToUser,
} from 'features/users/api';
import * as Types from 'types';

export const mapUserResponseToLoggedInUserType = (
  response: ResponseLoggedInUser,
): Types.LoggedInUser => ({
  ...mapResponseUserToUser(response),
  editionsCount: response.editions_count,
  followingCount: response.followers_count,
  followersCount: response.following_count,
  invitedUsersCount: response.invited_users_count,
  referralCreditsEarned: response.referral_credits_earned,
  inviteCodeIos: response.invite_code_ios,
  artStreamActive: Boolean(response.art_stream_active),
  accessToken: response.access_token,
  followedUsers: response.followed_users,
  followedPlaylists: response.followed_playlists,
});

export const requestCurrentUser = () =>
  request
    .get<ResponseLoggedInUser>(`/users/${sedition.currentUser.id}.json`)
    .then(({ data }) => {
      return mapUserResponseToLoggedInUserType(data);
    });

export const requestCurrentUserInternal = () =>
  request
    .get<ResponseLoggedInUser>(
      `/api/internal/users/${sedition.currentUser.id}.json?serializer=internal`,
    )
    .then(({ data }) => {
      return mapUserResponseToLoggedInUserType(data);
    });
