import React, { useState, useEffect } from 'react';
import { useDrag } from 'react-use-gesture';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { ArtworkSlide } from 'apps/homepage/components/ArtworkSlide';
import { CustomSlide } from 'apps/homepage/components/CustomSlide';
import * as Types from 'types';

const { homePageSlides }: { homePageSlides: Types.HomePageSlide[] } = window.sedition as any;

const trackSlideChange = (position: 'previous' | 'next' | 'unknown', slideId: number) => {
  window.dataLayer.push({
    event: 'custom-event',
    category: 'hero',
    action: `show-${position}-slide`,
    label: '',
    params: {
      slideId,
    },
  });
};

export const HomePageHero = () => {
  const container = document.querySelectorAll('.js-hero-slate')[0];
  if (!container) {
    return null;
  }
  const [activeSlide, setActiveSlide] = useState<number | null>(0);
  const [nextSlideLoaded, setNextSlideLoaded] = useState<boolean>(false);
  const slideCount = homePageSlides.length;

  const goToSlide = (slideId: number) => {
    let type: 'previous' | 'next' | 'unknown' = 'unknown';

    if (activeSlide === slideId - 1) {
      type = 'next';
    } else if (activeSlide === slideId + 1) {
      type = 'previous';
    } else if (activeSlide !== null && activeSlide > slideId) {
      type = 'next';
    } else {
      type = 'previous';
    }

    if (activeSlide) {
      trackSlideChange(type, activeSlide);
    }

    setActiveSlide(null);
    setActiveSlide(slideId);
  };

  const onClickNextSlide = () => {
    let nextSlide = activeSlide !== null ? activeSlide + 1 : 0;

    if (nextSlide === slideCount) {
      nextSlide = 0;
    }

    goToSlide(nextSlide);
  };

  const onClickPreviousSlide = () => {
    let previousSlide = activeSlide !== null ? activeSlide - 1 : 0;

    if (previousSlide < 0) {
      previousSlide = slideCount - 1;
    }

    goToSlide(previousSlide);
  };

  const bindDrag = useDrag(({ direction: [xDir], distance, cancel, canceled }) => {
    if (xDir > 0 && distance > window.innerWidth / 2 && !canceled) {
      if (cancel) {
        cancel();
      }
      onClickPreviousSlide();
    } else if (xDir < 0 && distance > window.innerWidth / 2 && !canceled) {
      if (cancel) {
        cancel();
      }
      onClickNextSlide();
    }
  });

  const onKeyDown = ({ keyCode }: KeyboardEvent) => {
    if (keyCode === Types.KEYS.ARROW_LEFT) {
      onClickPreviousSlide();
    } else if (keyCode === Types.KEYS.ARROW_RIGHT) {
      onClickNextSlide();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  const onClickExactSlide = (slideId: number) => {
    goToSlide(slideId);
  };

  const shouldPreload = (index: number): boolean => {
    const isNextSlide =
      index - 1 === activeSlide || (activeSlide === slideCount - 1 && index === 0);

    const isPreviousSlide =
      index + 1 === activeSlide || (activeSlide === 0 && index === slideCount - 1);

    if (isNextSlide) {
      return true;
    } else if (isPreviousSlide && nextSlideLoaded) {
      return true;
    }

    return false;
  };

  const onFirstFrame = (index: number) => {
    if (!nextSlideLoaded && index === 1) {
      setNextSlideLoaded(true);
    }
  };

  return ReactDOM.createPortal(
    <div
      {...bindDrag()}
      className={classNames(
        'homepage-hero hero-slate horizontal-navigation--inner show-artwork-info hero--rendered rendered',
        {
          'show-controls': slideCount > 1,
        },
      )}
    >
      {homePageSlides.map((slide, index: number) => {
        return !!slide.artwork ? (
          <ArtworkSlide
            key={`artwork_${index}`}
            slide={slide}
            active={index === activeSlide}
            shouldPreload={shouldPreload(index)}
            onFirstFrame={() => onFirstFrame(index)}
          />
        ) : (
          <CustomSlide key={`custom_${index}`} slide={slide} active={index === activeSlide} />
        );
      })}
      <button
        onClick={onClickNextSlide}
        className={classNames(
          'button horizontal-navigation__next horizontal-navigation--no-gutter black-transparent',
          {
            hide: slideCount <= 1,
          },
        )}
      />
      <button
        onClick={onClickPreviousSlide}
        className={classNames(
          'button horizontal-navigation__previous horizontal-navigation--no-gutter black-transparent',
          {
            hide: slideCount <= 1,
          },
        )}
      />
      <div className='inline-paging inline-paging--rounded white'>
        {homePageSlides.map((_slide, index: number) => (
          <a
            href='#'
            key={`pagination_${index}`}
            onClick={() => onClickExactSlide(index)}
            className={classNames({ active: index === activeSlide })}
          />
        ))}
      </div>
    </div>,
    container,
  );
};
