import { useState, useEffect } from 'react';
import throttle from 'lodash/throttle';

const defaultEvents = [
  'mousemove',
  'mousedown',
  'resize',
  'keydown',
  'touchstart',
  'wheel',
];
const oneMinute = 60e3;

export const useUserInputIdle = (
  duration = oneMinute,
  initialState = false,
  events = defaultEvents,
): boolean => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    let mounted = true;
    let timeout: any;
    let localState: boolean = state;

    /**
     * Updates state only if still mounted.
     */
    const updateState = (newState: boolean) => {
      if (mounted) {
        localState = newState;
        setState(newState);
      }
    };

    const onEvent = throttle(() => {
      if (localState) {
        updateState(false);
      }

      clearTimeout(timeout);
      timeout = setTimeout(() => updateState(true), duration);
    }, 50);

    const onVisibility = () => {
      if (!document.hidden) {
        onEvent();
      }
    };

    for (let i = 0; i < events.length; i++) {
      window.addEventListener(events[i], onEvent);
    }
    document.addEventListener('visibilitychange', onVisibility);

    timeout = setTimeout(() => updateState(true), duration);

    return () => {
      mounted = false;

      for (let i = 0; i < events.length; i++) {
        window.removeEventListener(events[i], onEvent);
      }
      document.removeEventListener('visibilitychange', onVisibility);
    };
  }, events);

  return state;
};
