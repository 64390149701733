import React, { useState, useEffect, useRef, useLayoutEffect, FC } from 'react';
import { connect } from 'react-redux';
import CustomScroll from 'react-custom-scroll';

import { Spinner } from 'components/Spinner';
import { CollapsableContent } from 'components/CollapsableContent';
import {
  fetchCurrentUserPlaylists,
  addArtworkToPlaylistAndSave,
  removeArtworkFromPlaylistAndSave,
} from 'features/playlists/actions';
import { isArtworkAvailableViaArtStream } from 'artworks/helpers';

import { useCurrentUser } from 'hooks/useCurrentUser';
import { useMediaQueries } from '@react-hook/media-query';
import { useSize } from 'hooks/useSize';

import { AddToPlaylistButton } from './AddToPlaylistButton';

import { PlaylistWidgetPlaylistItem } from './PlaylistWidgetPlaylistItem';

import * as Types from 'types';
import { theme } from 'services/theme';
import { onClickTracking } from 'utils/tracking/trackingHelpers';

type StateProps = {
  playlists: Types.Playlist[];
  isFetching: boolean;
};

type DispatchProps = {
  fetchCurrentUserPlaylists: () => void;
  addArtworkToPlaylistAndSave: (artwork: Types.Artwork, playlistId: number) => Promise<void>;
  removeArtworkFromPlaylistAndSave: (artworkId: number, playlistId: number) => Promise<void>;
};

type OwnProps = {
  artwork: Types.Artwork;
  modalRef?: any;
};

type Props = StateProps & DispatchProps & OwnProps;

type State = {
  byId: { [playlistId: string]: ExtendedPlaylist };
};

type PlaylistUIState = 'idle' | 'added' | 'removed' | 'error' | 'loading';

type ExtendedPlaylist = {
  artworkInPlaylist: boolean;
  message?: string;
  state: PlaylistUIState;
  previousState: PlaylistUIState;
} & Types.Playlist;

const LIST_MARGIN_Y = 8;

const isArtworkInPlaylist = (artworkId: number, items: Types.Playlist['items']) =>
  items?.findIndex((item) => item.artwork.id === artworkId) !== -1;

const AddToPlaylist: FC<Props> = (props) => {
  const [state, setState] = useState<State>({ byId: {} });
  const currentUser = useCurrentUser();
  const playlists = props.playlists.filter((playlist) => playlist.userId === currentUser?.id);

  useEffect(() => {
    if (isArtworkAvailableViaArtStream(props.artwork) && playlists.length === 0) {
      props.fetchCurrentUserPlaylists();
    }
  }, []);

  const setPlaylistUIState = (
    playlistId: string,
    payload: { state: PlaylistUIState; message?: string },
  ) => {
    const playlistState = state.byId?.[playlistId];

    setState({
      byId: {
        ...state.byId,
        [playlistId]: {
          ...state.byId?.[playlistId],
          state: payload?.state,
          message: payload?.message || playlistState?.message,
        },
      },
    });
  };

  const onAPIError = (playlistId: string) => {
    setPlaylistUIState(playlistId, { state: 'error', message: 'Error occurred, try again' });
  };

  const onRemoveFromPlaylistClick = (playlist: Types.Playlist) => {
    setPlaylistUIState(playlist.id, { state: 'loading' });

    props
      .removeArtworkFromPlaylistAndSave(props.artwork.id, parseInt(playlist.id, 10))
      .then(() => {
        setPlaylistUIState(playlist.id, {
          state: 'idle',
        });
      })
      .catch(() => onAPIError(playlist.id));
  };

  const onAddToPlaylistClick = (playlist: Types.Playlist) => {
    setPlaylistUIState(playlist.id, { state: 'loading' });

    props
      .addArtworkToPlaylistAndSave(props.artwork, parseInt(playlist.id, 10))
      .then(() => {
        setPlaylistUIState(playlist.id, {
          state: 'idle',
        });
      })
      .catch(() => onAPIError(playlist.id));
  };

  if (!isArtworkAvailableViaArtStream(props.artwork)) {
    return null;
  }

  const { isFetching } = props;

  const { matchesAll: isMobile } = useMediaQueries({
    width: '(max-width: 414px)',
  });

  const widgetRef = useRef<HTMLDivElement>(null);

  const modalRect = props.modalRef?.current?.getBoundingClientRect();
  const widgetRect = widgetRef?.current?.getBoundingClientRect();

  const height = modalRect && widgetRect && Math.round(widgetRect?.top - modalRect?.top);

  const [widgetHeight, setWidgetHeight] = useState<number>();

  useLayoutEffect(() => {
    setWidgetHeight(height);
  }, [widgetRef.current, height]);

  //A hook used to watch for modal resizing to recalculate the height of the "AddToPlaylistWidget" dropdown.
  useSize(props.modalRef?.current);

  return (
    <CollapsableContent>
      {({ isOpen, trigger }) => {
        return (
          <div className='pos-rel' style={{ borderRadius: 1 }} ref={widgetRef}>
            <AddToPlaylistButton onClick={trigger} className='bg-dark-gray'>
              <div
                className={`translate-into-middle-y icon-${
                  isOpen === (isMobile || !widgetHeight) ? 'up' : 'down'
                }`}
                style={{ right: theme.gutter, marginTop: 1 }}
              />
            </AddToPlaylistButton>
            <div
              className={`text-light text-light--subtle-hover tal w-100 zi-1 bg-dark-gray add-to-playlist--widget--item`}
              style={{
                position: isMobile ? 'initial' : 'absolute',
                left: 0,
                bottom: widgetHeight && !isMobile ? '100%' : 'unset', // Should be 100% but for some reason on Chrome there's a 0.5px gap between two backgrounds
                display: isOpen ? 'block' : 'none',
                borderRadius: '4px',
                marginTop: `${LIST_MARGIN_Y}px`,
                marginBottom: `${LIST_MARGIN_Y}px`,
              }}
            >
              {!isFetching && (
                <CustomScroll
                  allowOuterScroll
                  // heightRelativeToParent={
                  //   widgetHeight && !isMobile ? `${widgetHeight - 75}px` : undefined
                  // }
                >
                  <div
                    className='pt-mini pb-1'
                    style={{
                      maxHeight: widgetHeight && !isMobile ? `${widgetHeight - 75}px` : 'none',
                    }}
                  >
                    {playlists.map((playlist) => (
                      <PlaylistWidgetPlaylistItem
                        playlistName={playlist.name}
                        key={playlist.id}
                        icon={
                          state.byId?.[playlist.id]?.state === 'loading'
                            ? 'loading'
                            : isArtworkInPlaylist(props.artwork.id, playlist.items)
                            ? 'checkmark'
                            : undefined
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          if (isArtworkInPlaylist(props.artwork.id, playlist.items)) {
                            onRemoveFromPlaylistClick(playlist);
                          } else {
                            onClickTracking(
                              'add_to_playlist',
                              'playlist',
                              `artwork_id: ${props.artwork.id}`,
                            );
                            onAddToPlaylistClick(playlist);
                          }
                        }}
                      />
                    ))}
                  </div>
                </CustomScroll>
              )}
              <div className='py-1'>
                {isFetching && (
                  <Spinner className='square-spin--light square-spin--xs mx-auto mb-1' />
                )}
                {!isFetching && playlists.length === 0 && (
                  <div className='mx-1'>
                    You haven't created any playlists. Start by creating a new playlist and fill it
                    with artworks of your choice.
                  </div>
                )}
                <a
                  href={Routes.vault_playlists_path()}
                  className='btn btn--border-light btn--xs d-block mx-1'
                >
                  Manage Playlists
                </a>
              </div>
            </div>
          </div>
        );
      }}
    </CollapsableContent>
  );
};

const mapStateToProps = (state: Types.RootState, ownProps: OwnProps) => {
  const playlists = Object.values(state.playlists.byId)
    .filter(({ type }) => type === 'Regular')
    .map((playlist) => ({
      ...playlist,
    }))
    .sort(({ id }) => -id);

  return {
    isFetching: state.playlists.isFetching,
    playlists,
  };
};

const mapDispatchToProps = {
  fetchCurrentUserPlaylists,
  addArtworkToPlaylistAndSave,
  removeArtworkFromPlaylistAndSave,
};

export const AddToPlaylistWidget = connect(mapStateToProps, mapDispatchToProps)(AddToPlaylist);
