import invert from 'lodash/invert';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import findIndex from 'lodash/findIndex';
import Cookies from 'js-cookie';

export type Quality = 'Auto' | '4K' | '2K' | 'HD' | 'SD' | 'MD' | 'LD';
const QualityMap: { [id in Quality]: number } = {
  Auto: 0,
  '4K': 1,
  '2K': 2,
  HD: 3,
  SD: 4, // 720
  MD: 5, // 540
  LD: 6, // 480
};

type KeyFromValue<V, T extends Record<PropertyKey, PropertyKey>> = {
  [K in keyof T]: V extends T[K] ? K : never;
}[keyof T];

type Invert<T extends Record<PropertyKey, PropertyKey>> = {
  [V in T[keyof T]]: KeyFromValue<V, T>;
};

export type QualityLevel = {
  index: number;
  label: Quality;
};

// Currently missing in JWPlayer types so we define it ourselves.
export type JWQualityLevel = {
  label?: string;
  level_id?: string;
  hlsjsIndex: number;
  bitrate: number;
  height?: number;
  width?: number;
};

export const levelsByQuality = (levels: JWQualityLevel[]): JWQualityLevel[] => {
  return sortBy(
    levels.map((level, index) => ({ ...level, index })),
    (level) => (level.label === 'auto' ? -Infinity : level.bitrate),
  );
};

export const jwLevelsToQualityLevels = (
  levels: JWQualityLevel[],
): QualityLevel[] => {
  if (levels.length === 1) {
    return [];
  }

  return compact(
    levels.map((level, index) => {
      const label = jwLevelToLabel(level, levels);
      return label
        ? {
            index: index,
            label: label,
          }
        : null;
    }),
  );
};

const jwLevelToLabel = (
  level: JWQualityLevel,
  levels: JWQualityLevel[],
): Quality | undefined => {
  if (level.hlsjsIndex === -1) {
    return 'Auto';
  }
  const { width, height } = level;
  if (width && height) {
    const wide = width > height ? width : height;
    if (wide > 2560) {
      return '4K';
    } else if (wide > 1920) {
      return '2K';
    } else if (wide === 1920) {
      return 'HD';
    } else if (wide === 1280) {
      return 'SD';
    } else if (wide > 920) {
      return 'MD';
    } else if (wide < 852) {
      return 'LD';
    } else {
      console.error(
        'A) Unable to determine how to label this quality level',
        level,
        levels,
      );
      return undefined;
    }
  } else {
    const levelIndex = findIndex(levels, (searchLevel) => {
      return searchLevel.bitrate === level.bitrate;
    });
    // Since here resolution is not available
    // width/height were nil then is max HD
    // We shift index mapping from:
    // 1 = 4k / 2 = 2K to:
    // 1 = HD / 2 = SD
    const remapedIndex = levelIndex + 2;
    if (remapedIndex < Object.keys(QualityMap).length) {
      const indexes = invert(QualityMap) as Invert<typeof QualityMap>;
      return indexes[remapedIndex];
    } else {
      console.error(
        'B) Unable to determine how to label this quality level',
        level,
        levels,
      );
    }
  }
};

export const qualityPreference = {
  get: (): Quality => {
    const _quality = Cookies.get('player-quality');
    switch (_quality) {
      case '4K':
        return '4K';
      case '2K':
        return '2K';
      case 'HD':
        return 'HD';
      case 'SD':
        return 'SD';
      case 'MD':
        return 'MD';
      case 'LD':
        return 'LD';
      default:
        return 'Auto';
    }
  },
  store: (quality: Quality) => {
    Cookies.set('player-quality', quality);
  },
};

export const qualityToIndex = (quality: Quality, levels: JWQualityLevel[]) => {
  if (quality === 'Auto') {
    return 0;
  }

  if (quality === '4K') {
    return 1;
  }

  if (levels[levels.length - 1].width === undefined) {
    bugsnag.notify('Warning: Level has undefined resolution.');
  }

  const levelsIndexes: { [id in Quality]: number } = {
    Auto: -1,
    '4K': findIndex(levels, (level) => {
      return level.width === 3840 || level.height === 2160;
    }),
    '2K': findIndex(levels, (level) => {
      return level.width === 2560 || level.height === 1440;
    }),
    HD: findIndex(levels, (level) => {
      return level.width === 1920 || level.height === 1280;
    }),
    SD: findIndex(levels, (level) => {
      return level.width === 1280 || level.height === 720;
    }),
    MD: findIndex(levels, (level) => {
      return (
        (level.width !== undefined &&
          level.width < 1280 &&
          level.width > 920) ||
        (level.height !== undefined && level.height < 720 && level.height > 517)
      );
    }),
    LD: findIndex(levels, (level) => {
      return (
        (level.width !== undefined && level.width < 852) ||
        (level.height !== undefined && level.height < 479)
      );
    }),
  };

  if (quality === '2K' || quality === 'HD') {
    // Note when 2K is not available, we return 1 (highest possible quality)
    return levelsIndexes[quality] === -1 ? 1 : levelsIndexes[quality];
  }

  if (quality === 'SD') {
    return levelsIndexes['SD'] === -1 ? 1 : levelsIndexes['SD'];
  }

  if (quality === 'MD' || quality === 'LD') {
    if (levelsIndexes[quality] === -1) {
      // When MD/LD are not found return lowest quality
      return levels.length - 1;
    } else {
      return levelsIndexes[quality];
    }
  }

  return 0;
};

export const qualityToLongLabel = (quality: Quality) => {
  if (quality === 'SD') {
    return 'Standard';
  } else if (quality === 'MD') {
    return 'Medium';
  } else if (quality === 'LD') {
    return 'Low';
  } else {
    return quality;
  }
};
