import { handleActions } from 'redux-actions';

import { OPEN, CLOSE } from 'apps/shared/features/modal/constants';
import { ModalData } from 'components/artworkPreviewModal/Container';

type ModalState = {
  isOpen: boolean;
  data: ModalData;
};

type ModalReducerShape = {
  byId: Record<string, ModalState>;
};

type ModalReducerPayload = {
  modalId: string;
  data: any;
};

const defaultState = {
  byId: {},
};

export const modalReducer = handleActions<ModalReducerShape, ModalReducerPayload>(
  {
    [OPEN]: (state, { payload }) => ({
      ...state,
      byId: {
        ...state.byId,
        [payload.modalId]: {
          ...state.byId[payload.modalId],
          isOpen: true,
          data: {
            ...(state.byId?.[payload.modalId]?.data || {}),
            ...payload.data,
          },
        },
      },
    }),
    [CLOSE]: (state, { payload }) => ({
      byId: {
        ...state.byId,
        [payload.modalId]: {
          isOpen: false,
          data: {
            ...(state.byId?.[payload.modalId]?.data || {}),
          },
        },
      },
    }),
  },
  defaultState,
);
