import * as Types from 'types';
import React, { FC, useState, useEffect } from 'react';
import { Transition } from 'react-transition-group';

import { shuffle } from 'lodash';
import { connect } from 'react-redux';

import { Artwork } from 'components/artworks/Artwork';
import { Artist } from 'components/artists/Artist';
import WithLoop from 'artworks/WithLoop';
import useWindowDimensions from 'hooks/useWindowDimensions';

type Props = {
  id?: string;
  initialArtworks?: Types.Artwork[];
  initialArtists?: Types.Artist[];
  items?: (Types.Artwork | Types.Artist)[];
  staggered?: boolean;
  itemWidth?: number;
  itemGutter?: number;
};

const VELOCITY = 60; // px/s

interface State {
  id?: string;
  duration: number;
  distance: number;
  itemsToShow: (Types.Artwork | Types.Artist)[];
}

const AnimatedCarouselComponent: FC<Props> = ({
  items = [],
  id,
  staggered = false,
  itemWidth = 260,
  itemGutter = 48,
  ...props
}) => {
  const { width } = useWindowDimensions();

  const [animatedCarouselComponentState, setAnimatedCarouselComponentState] = useState<State>({
    id: '',
    duration: 0,
    distance: 0,
    itemsToShow: [],
  });
  const [carouselItems, setCarouselItems] = useState([] as JSX.Element[]);

  useEffect(() => {
    if (items.length) {
      const numVisibleItems = Math.round(width / itemWidth);

      const evenNumVisibleItems = numVisibleItems % 2 === 0 ? numVisibleItems : numVisibleItems + 1;
      const safeItems =
        items.length < evenNumVisibleItems
          ? Array(Math.ceil(evenNumVisibleItems / items.length))
              .fill(items)
              .flat()
          : items;

      const evenNumItems = safeItems.length % 2 === 0 ? safeItems : [...safeItems, ...safeItems];

      const itemsToShow = [...evenNumItems, ...evenNumItems.slice(0, evenNumVisibleItems)];

      const distance = evenNumItems.length * (itemWidth + itemGutter);
      const duration = distance / VELOCITY;

      setAnimatedCarouselComponentState({
        id,
        distance,
        duration,
        itemsToShow,
      });
    }
  }, [width, items.length]);

  const checkThumbnailType = (item: Types.Artwork | Types.Artist, idx: number) => {
    if (item.thumbnailType === 'artwork') {
      return (
        <Artwork
          key={`${item.url}__${idx}`}
          className={staggered ? 'animated-carousel__item--staggered' : 'animated-carousel__item'}
          artwork={item}
        />
      );
    } else {
      return (
        <Artist
          key={`${item.url}__${idx}`}
          className={staggered ? 'animated-carousel__item--staggered' : 'animated-carousel__item'}
          artist={item}
        />
      );
    }
  };

  useEffect(() => {
    if (animatedCarouselComponentState.itemsToShow.length > 0) {
      const carouselItemsArray = animatedCarouselComponentState.itemsToShow.map((item, index) => {
        return checkThumbnailType(item, index);
      });
      setCarouselItems(carouselItemsArray);
    }
  }, [animatedCarouselComponentState.itemsToShow]);

  if (animatedCarouselComponentState.itemsToShow.length > 0) {
    return (
      <Transition appear in={animatedCarouselComponentState.itemsToShow.length > 0} timeout={1}>
        {(transitionState) => (
          <div className={`animated-carousel animated-carousel--state-${transitionState}`}>
            <WithLoop
              id={animatedCarouselComponentState.id}
              distance={animatedCarouselComponentState.distance}
              duration={animatedCarouselComponentState.duration}
            >
              <div className='animated-carousel__items'>{carouselItems}</div>
            </WithLoop>
          </div>
        )}
      </Transition>
    );
  } else {
    // This is to prevent the flow jumping when the carousel loads
    return <div style={{ height: '260px' }}></div>;
  }
};

const mapStateToProps = (state: Types.RootState, props: Props) => {
  const { initialArtworks = [], initialArtists = [] } = props;
  if (initialArtworks.length !== 0) {
    return {
      items: shuffle(initialArtworks) || [],
    };
  } else
    return {
      items: initialArtists || [],
    };
};

export const AnimatedCarousel = connect(mapStateToProps)(AnimatedCarouselComponent);
