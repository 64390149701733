import { useContext } from 'react';

import { ChromecastContext } from 'context/ChromecastContext';
import { ChromecastContextValue } from 'context/ChromecastContext/types';

export const useChromecast = (): ChromecastContextValue => {
  const context = useContext(ChromecastContext);
  if (context === undefined) {
    throw new TypeError('useChromecast must be used within a ChromecastProvider');
  }
  return context;
};
