import React, { useEffect } from 'react';

import { CDN_ASSETS_URL } from 'constants/global';

type Props = {
  title: string;
  text: string;
  btnText: string;
  btnLink: string;
};

const PdlCta = (props: Props) => {
  const { lintrk } = window;

  const ctaParallax = (e: MouseEvent, container: HTMLElement) => {
    const overlay = document.getElementsByClassName('pdl-cta__overlay')[0] as HTMLElement;
    const x = (container.clientWidth - e.clientX) / 8;
    const y = (container.clientHeight - e.clientY) / 5;
    overlay.style.transform = `translateX(${x}px) translateY(${y}px)`;
  };

  useEffect(() => {
    if (window.innerWidth > 767) {
      const container = document.getElementsByClassName('pdl-cta__container')[0] as HTMLElement;
      if (container) {
        container.addEventListener('mousemove', (e) => ctaParallax(e, container));
      }
    }
  }, []);

  return (
    <div className='pdl-cta__container public-displays__content'>
      <div className='pdl-cta__content'>
        <div className='icon-four-point-star' />
        <h2 className='public-displays__subtitle'>{props.title}</h2>
        <p className='pdl-cta__text'>{props.text}</p>
        <a
          className='btn btn--cta'
          href={props.btnLink}
          target='_blank'
          onClick={() => lintrk('track', { conversion_id: 12334465 })}
        >
          {props.btnText}
        </a>
      </div>
      <img
        className='pdl-cta__base'
        src={`${CDN_ASSETS_URL}public-displays/gradient-base.png`}
        alt=''
      />
      <img
        className='pdl-cta__overlay'
        src={`${CDN_ASSETS_URL}public-displays/gradient-overlay.png`}
        alt=''
      />
    </div>
  );
};

export default PdlCta;
