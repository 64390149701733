import React from 'react';

export const PromoText = () => (
  <div className='homepage__description-sentence mb-4'>
    <h1 className='standfirst standfirst--lead text-center mb-1 mt-2'>
      Sedition is a platform for selling and collecting art in digital format. Collect, trade and
      enjoy artworks on your screens.
    </h1>
  </div>
);
