import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';

/**
 * When dispatched action contains dataLayerPush in payload
 * it will pass it's contents to dataLayer.push.
 *
 * Eg. to track event using GTM:
 *
 * showModal({
 *   modalId,
 *   dataLayerPush: {
 *     event: 'custom-event',
 *     category: 'modals',
 *     action: 'show-quick-view-modal',
 *   }
 * });
 *
 */
const generateLabelForCustomEvent = (
  dataLayerData: any,
  store: MiddlewareAPI<Dispatch<AnyAction>, any>,
) => {
  if (dataLayerData.event == 'custom-event' && dataLayerData.artworkId) {
    const state = store.getState();
    const artwork = state.artworks.byId[dataLayerData.artworkId];

    if (artwork) {
      return {
        ...dataLayerData,
        label: `${artwork.title} by ${artwork.artist_name}`,
      };
    }
  }

  return dataLayerData;
};

export const trackingMiddleware: Middleware = (store) => (next) => (action) => {
  const { payload } = action;

  if (payload && payload.dataLayerPush) {
    let dataLayerData = payload.dataLayerPush;
    dataLayerData = generateLabelForCustomEvent(dataLayerData, store);
    dataLayer.push(dataLayerData);
  }

  return next(action);
};
