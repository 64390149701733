import React from 'react';
import { getOpenSeaAddress } from 'services/nfts';

const getFormAction = (id: number, version: string) =>
  `/cart/cart_items?artwork_id=${id}/version=${version}`;

const isLoggedIn = window.sedition.currentUser.id !== undefined;

type Props = {
  id: number;
  version: string;
  availableForPurchase: boolean;
  unavailableEditionsCount: number;
  isNFT: boolean;
};

export const BuyForm: React.FC<Props> = ({
  id,
  version,
  availableForPurchase,
  unavailableEditionsCount,
  isNFT,
}) => (
  <div>
    {availableForPurchase ? (
      isLoggedIn ? (
        <form action={getFormAction(id, version)} method='post' className='mb-0'>
          <input
            type='hidden'
            name={$('meta[name=csrf-param]').attr('content')}
            value={$('meta[name=csrf-token]').attr('content')}
          />
          <button type='submit' className='btn btn--lg bg-cta w-100'>
            Buy
          </button>
        </form>
      ) : (
        <a
          href={`/signup?sticky_intent=add_artwork_to_cart&sticky_intent_id=${id}`}
          className='btn btn--lg bg-cta btn-buy w-100'
        >
          Buy
        </a>
      )
    ) : null}
    {!availableForPurchase && unavailableEditionsCount > 0 && (
      <a
        href={isNFT ? getOpenSeaAddress() : `/trade/marketplace/artwork/${id}/bids/new`}
        className='btn btn--lg bg-cta btn-buy w-100 d-flex align-items-center'
        style={{ maxHeight: '50px' }}
        target={isNFT ? '_blank' : '_self'}
      >
        <div className='d-flex align-items-center justify-content-center' style={{ width: '100%' }}>
          {isNFT ? (
            <>
              <span className='icon icon-opensea mr-mini' style={{ fontSize: '24px' }} />
              View on OpenSea
            </>
          ) : (
            'View on Trade'
          )}
        </div>
      </a>
    )}
  </div>
);
