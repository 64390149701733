import React from 'react';

import { PdlLogo } from '.';
import FadeAndSlide from 'components/animations/FadeAndSlide';

export interface Props {
  logos: { name: string; logo: string; url: string; size?: 'small' | 'tiny' }[];
}

const PdlLogos = ({ logos }: Props) => {
  const largeTopLogos = logos.slice(0, logos.length / 2);
  const largeBottomLogos = logos.slice(logos.length / 2);

  const smallTopLogos = logos.slice(0, logos.length / 3);
  const smallMidLogos = logos.slice(logos.length / 3, (logos.length * 2) / 3);
  const smallBottomLogos = [logos[7], logos[6], logos[8]]; // change this if logo ordering changes

  const largeLogos = [largeTopLogos, largeBottomLogos];
  const smallLogos = [smallTopLogos, smallMidLogos, smallBottomLogos];

  return (
    <>
      <div className='pdl-logos'>
        {smallLogos.map((logoGroup, idx) => (
          <div key={`logo-group-${idx}`}>
            {logoGroup.map((logo, logoIdx) => (
              <FadeAndSlide
                key={`logo-group-${idx}-logo${logoIdx}`}
                delay={100 * (Math.floor((logos.length * idx) / smallLogos.length) + logoIdx)}
              >
                <PdlLogo logo={logo} />
              </FadeAndSlide>
            ))}
          </div>
        ))}
      </div>
      <div className='pdl-logos--large'>
        {largeLogos.map((logoGroup, idx) => (
          <div key={`large-logo-group-${idx}`}>
            {logoGroup.map((logo, logoIdx) => (
              <FadeAndSlide
                key={`large-logo-group-${idx}-logo${logoIdx}`}
                delay={100 * (Math.floor((logos.length * idx) / largeLogos.length) + logoIdx)}
              >
                <PdlLogo logo={logo} />
              </FadeAndSlide>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default PdlLogos;
