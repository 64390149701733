import * as Types from 'types';

export enum CurrentUserActionTypes {
  RECEIVE_CURRENT_USER = '@currentUser/RECEIVE_CURRENT_USER',
  RECEIVE_USER_FOLLOWINGS = '@currentUser/RECEIVE_USER_FOLLOWINGS',
  RECEIVE_PLAYLIST_FOLLOWINGS = '@currentUser/RECEIVE_PLAYLIST_FOLLOWINGS',
}

export interface CurrentUserState {
  readonly user?: Types.LoggedInUser;
  readonly followedUsers: number[];
  readonly followedPlaylists: number[];
}
