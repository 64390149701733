import React, { FC } from 'react';

type Props = {
  onClick: () => void;
  className: string;
};

export const AddToPlaylistButton: FC<Props> = ({ onClick, className, children }) => {
  return (
    <div className={`btn btn--lg w-100 bg-dark-gray ${className}`} onClick={onClick}>
      Add to playlist
      {children}
    </div>
  );
};
