import React, { FC } from 'react';
import ContentLoader from 'react-content-loader';

import { useLoadImage } from 'hooks/useLoadImage';
import { ConditionalAnchor } from 'components/ConditionalAnchor';

type Sizes = 'smaller' | 'small';
const SizeMap: Record<Sizes, number> = {
  small: 67,
  smaller: 50,
};

type Props = {
  name: string;
  url?: string;
  avatarUrl?: string;
  className?: string;
  size?: Sizes;
};

export const Avatar: FC<Props> = ({ url, avatarUrl, name, className, size = 'smaller' }) => {
  const isLoaded = useLoadImage(avatarUrl);

  return (
    <ConditionalAnchor
      className={`avatar avatar--${size} avatar--squared d-inline-block ${className || ''}`}
      href={url}
      shouldWrap={!!url}
    >
      {avatarUrl && isLoaded && (
        <img
          alt={`Profile picture of ${name}`}
          width={SizeMap[size]}
          height={SizeMap[size]}
          src={avatarUrl}
        />
      )}
      {(!avatarUrl || !isLoaded) && (
        <ContentLoader
          height={SizeMap[size]}
          width={SizeMap[size]}
          speed={2}
          foregroundColor='#f3f3f3'
          backgroundColor='#ecebeb'
        >
          <rect x='0' y='0' rx='0' ry='0' width='50' height='50' />
        </ContentLoader>
      )}
    </ConditionalAnchor>
  );
};
