import * as Types from 'types';

export enum MediaActionTypes {
  FETCH_PREVIEW = '@media/FETCH_PREVIEW',
  RECEIVE_PREVIEW = '@media/RECEIVE_PREVIEW',
}

export interface MediaState {
  readonly previews: {
    [id: number]: Types.Media;
  };
}
