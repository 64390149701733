import * as Types from 'types';

import { fetchMedia } from 'player/services/fetchMedia';

/**
 * Returns artwork if it has media data. If not
 * returns promise and fetches URLs from API.
 */
export const ensureMediaUrl = function (
  artwork: Types.Artwork,
): Promise<string> {
  if (artwork.type === 'video') {
    // @ts-ignore
    if (artwork.videos && (artwork.videos.adaptive || artwork.videos.hd)) {
      // Never happens
      // Artworks that are not fully processed won't have adaptive source.
      // In that case use HD version of mp4 file.
      // @ts-ignore
      return Promise.resolve(artwork.videos.adaptive || artwork.videos.hd);
    } else {
      return fetchMedia(artwork.id);
    }
  } else {
    // @ts-ignore
    if (artwork.images && artwork.images.hd) {
      // Never happens
      // @ts-ignore
      return Promise.resolve(artwork.images.hd);
    } else {
      return fetchMedia(artwork.id);
    }
  }
};
