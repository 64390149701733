import { createAction } from 'redux-actions';
import { request } from 'utils/request';

import {
  FETCH_LISTINGS,
  FETCH_ARTWORKS,
  RECEIVE_LISTINGS,
  RECEIVE_ARTWORKS,
  RECEIVE_MORE_ARTWORKS,
  CHANGE_CURRENT_LISTING,
  FETCH_ARTWORK,
  RECEIVE_ARTWORK,
  SET_END_OF_RESULTS,
} from 'apps/shared/features/artworks/constants';
import { endpoints } from 'apps/shared/features/artworks/endpoints';

import { requestArtworks } from 'features/artworks/api';
import { ThunkAction } from 'redux-thunk';
import { HomepageRootState } from 'apps/homepage/store';
import { AnyAction } from 'redux';
import { LegacyArtwork, Listing } from 'types';
import { ArtworkType } from './reducer';
import { Filter } from 'features/artworks/api/requestArtworks';
import { ModalData } from 'components/artworkPreviewModal/Container';
import { breakpoints } from 'services/theme';

const receiveListings = createAction(RECEIVE_LISTINGS);
const receiveArtworks = createAction(RECEIVE_ARTWORKS);
const receiveMoreArtworks = createAction(RECEIVE_MORE_ARTWORKS);
const receiveArtwork = createAction(RECEIVE_ARTWORK);
const setEndOfResults = createAction(SET_END_OF_RESULTS);
export const changeCurrentListing = createAction(CHANGE_CURRENT_LISTING);

/*
Landing page grid titles are retrieved from the DB.
We don't want to display the word curated in "Recommended curated" in the tab title for mobile screens.
But we also don't want to change it in the DB because it's used for tablet/desktop.
*/
const removeFromTitle = (title: string, wordToRemove: string) => {
  return title.replace(`${wordToRemove}`, '').replace(/\s+/g, ' ').trim();
};

export const fetchListings =
  (): ThunkAction<void, HomepageRootState, unknown, AnyAction> => (dispatch, getState) => {
    const { artworks } = getState();

    if (
      Object.keys(artworks.byType.curated.byId).length > 0 ||
      artworks.byType.curated.isFetching
    ) {
      return;
    }

    dispatch(createAction(FETCH_LISTINGS)());

    request.get<{ slates: Listing[] }>(endpoints.listings('guest-user')).then((res) => {
      if (res && res.data) {
        const { slates } = res.data;
        dispatch(
          receiveListings({
            listings: slates.map(({ id, title, position }) => ({
              id: id.toString(),
              title: window.innerWidth < breakpoints.md ? removeFromTitle(title, 'Curated') : title,
              position: position || 0,
            })),
          }),
        );
      }
    });
  };

export const fetchArtworks =
  (
    listingId: string,
    loadMore = false,
    type: ArtworkType = 'curated',
  ): ThunkAction<void, HomepageRootState, unknown, AnyAction> =>
  (dispatch, getState) => {
    const { artworks } = getState();

    if (artworks.byType[type].byId[listingId].list.length > 0 && !loadMore) {
      return false;
    }

    if (loadMore && artworks.byType[type].byId[listingId].endOfResults) {
      return false;
    }

    const page = loadMore ? artworks.byType[type].byId[listingId].currentPage + 1 : 1;

    dispatch(createAction(FETCH_ARTWORKS)({ id: listingId, type }));

    const requestParams = {
      perPage: artworks.byType[type].perPage,
      page,
      filter: ['slate', type] as Filter[],
      slate: listingId,
    };

    requestArtworks(requestParams, 'legacy').then((receivedArtworks) => {
      const actionParams = {
        id: listingId,
        artworks: receivedArtworks,
        currentPage: page,
        endOfResults:
          artworks.byId && Object.keys(artworks.byId).length < artworks.byType.curated.perPage,
        type,
      };

      dispatch(loadMore ? receiveMoreArtworks(actionParams) : receiveArtworks(actionParams));

      if (receivedArtworks.length <= artworks.byType.curated.perPage) {
        requestArtworks({ ...requestParams, page: requestParams?.page + 1 }, 'legacy').then(
          (moreArtworks) => {
            if (moreArtworks.length === 0) {
              dispatch(setEndOfResults({ type, id: listingId }));
            }
          },
        );
      }
    });
  };

export const fetchArtwork =
  (
    data: ModalData,
    artwork: LegacyArtwork,
  ): ThunkAction<void, HomepageRootState, unknown, AnyAction> =>
  (dispatch, getState) => {
    const { artworks } = getState();

    const targetArtwork = artworks.byType[data.type].byId[data.listing].list.find(
      ({ id }) => id === artwork.id,
    );

    if (targetArtwork?.isFull) {
      return false;
    }

    dispatch(createAction(FETCH_ARTWORK)({ type: data.type }));

    request.get(endpoints.artwork(artwork.short_name)).then((res) => {
      if (res && res.data) {
        dispatch(
          receiveArtwork({
            artwork: res.data,
            id: data.listing,
            type: data.type,
          }),
        );
      }
    });
  };
