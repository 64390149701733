import React from 'react';

type Props = {
  prevArtworkId: number | null;
  nextArtworkId: number | null;
  onChangeArtwork: (data: { artworkId: number }) => void;
};

export const ModalControls: React.FC<Props> = ({
  prevArtworkId,
  nextArtworkId,
  onChangeArtwork,
}) => (
  <div className='artwork-preview__controls'>
    {prevArtworkId && (
      <button
        className='artwork-preview__control artwork-preview__control--left'
        onClick={() =>
          onChangeArtwork({
            artworkId: prevArtworkId,
          })
        }
      />
    )}
    {nextArtworkId && (
      <button
        className='artwork-preview__control artwork-preview__control--right'
        onClick={() =>
          onChangeArtwork({
            artworkId: nextArtworkId,
          })
        }
      />
    )}
  </div>
);
