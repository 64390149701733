import { request } from 'utils/request';

import * as Types from 'types';

import { isPlaylistSaved } from 'features/playlists/helpers';
import { PlaylistResponse, mapPlaylistResponseToPlaylist } from './index';

type PlaylistItemPayload = {
  id?: number;
  position?: number;
  artwork_id: number;
};

type PlaylistPayload = {
  id?: number;
  name: string;
  items_attributes: PlaylistItemPayload[];
};

const mapPlaylistToPlaylistPayload = (
  playlist: Types.Playlist,
): PlaylistPayload => {
  const playlistPayload: PlaylistPayload = {
    name: playlist.name,
    items_attributes: playlist.items.map((playlistItem) => {
      return {
        id: /^\d+$/.test(playlistItem.id)
          ? parseInt(playlistItem.id, 10)
          : undefined,
        position: playlistItem.position,
        artwork_id: playlistItem.artwork.id,
      };
    }),
  };

  return playlistPayload;
};

export const requestSavePlaylist = (playlist: Types.Playlist) => {
  const isSaved = isPlaylistSaved(playlist);

  let payload = mapPlaylistToPlaylistPayload(playlist);
  if (!isSaved) {
    delete payload.id;
    payload.items_attributes.forEach(
      (itemAttribute) => delete itemAttribute.id,
    );
  }

  return isSaved
    ? request
        .put<PlaylistResponse>(`/api/internal/playlists/${playlist.id}`, {
          playlist: payload,
        })
        .then(({ data }) => {
          return mapPlaylistResponseToPlaylist(data);
        })
    : request
        .post<PlaylistResponse>('/api/internal/playlists', {
          playlist: payload,
        })
        .then(({ data }) => mapPlaylistResponseToPlaylist(data));
};
