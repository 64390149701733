import { action } from 'typesafe-actions';

import * as Types from 'types';

import { CurrentUserActionTypes } from './types';
import { requestCurrentUser } from './api';

export const receiveCurrentUser = (user: Types.LoggedInUser) =>
  action(CurrentUserActionTypes.RECEIVE_CURRENT_USER, user);

export const receiveUserFollowings = (followings: number[]) =>
  action(CurrentUserActionTypes.RECEIVE_USER_FOLLOWINGS, followings);

export const receivePlaylistFollowings = (followings: number[]) =>
  action(CurrentUserActionTypes.RECEIVE_PLAYLIST_FOLLOWINGS, followings);

export const Actions = {
  receiveCurrentUser,
  receiveUserFollowings,
  receivePlaylistFollowings,
};

export const fetchCurrentUser = (): Types.DefaultThunkAction => (dispatch) =>
  requestCurrentUser().then((user) => {
    dispatch(receiveCurrentUser(user));
  });
