import { ChromecastPlayer } from 'artworkPlayer/chromecast';

import { PlaybackState } from 'artworkPlayer/types';
import * as Types from 'types';

export type ChromecastContextValue = {
  player?: ChromecastPlayer;
} & ChromecastState;

export type ChromecastSettings = {
  screenOrientation?: Types.ScreenOrientationOptions;
  showTitle?: Types.ShowTitleOptions;
};

export type ChromecastState = {
  available?: boolean;
  connected?: boolean;
  currentArtwork?: any;
  receiverPlaybackState?: PlaybackState;
  settings?: ChromecastSettings;
};

export enum ActionKind {
  UPDATE_DEVICE_STATE = 'UPDATE_DEVICE_STATE',
  UPDATE_SESSION_STATE = 'UPDATE_SESSION_STATE',
  // Actions from receiver app
  UPDATE_CURRENT_ARTWORK = 'UPDATE_CURRENT_ARTWORK',
  UPDATE_RECEIVER_PLAYER_STATE = 'UPDATE_RECEIVER_PLAYER_STATE',
  UPDATE_SETTINGS_PLAYER_STATE = 'UPDATE_SETTINGS_PLAYER_STATE',
}
