import React, { useEffect, useState } from 'react';
import { getSource, onClickTracking } from 'utils/tracking/trackingHelpers';

declare global {
  interface Window {
    lintrk: Function;
  }
}

export interface Props {
  text: string;
  swappingText: string[];
  btnText: string;
  btnLink: string;
}

const PdlHeroText = (props: Props) => {
  const [heroWordIdx, setHeroWordIdx] = useState(0);

  const heroText = props.text.split(' ').map((word, idx) => {
    if (idx % 2 === 0) {
      return <span key={idx}>{word} </span>;
    } else {
      return (
        <React.Fragment key={idx}>
          <span>{word}</span>
          <br />
        </React.Fragment>
      );
    }
  });

  const onClickHandler = () => {
    // google analytics tracking
    onClickTracking('book_a_call', 'pdl-booking', getSource());
    // linkedIn tracking
    window.lintrk('track', { conversion_id: 12334465 });
  };

  let idx = 0;

  useEffect(() => {
    setTimeout(() => {
      const changeWord = setInterval(() => {
        setHeroWordIdx(++idx);
        if (idx === props.swappingText.length - 1) {
          window.clearInterval(changeWord);
        }
      }, 1500);
    }, 1000);
  }, []);

  return (
    <div>
      <h1 className='pdl-hero__title'>
        {heroText}
        <span className='pdl-hero__title--animated'>{props.swappingText[heroWordIdx]}</span>
      </h1>
      <a
        href={props.btnLink}
        className='btn btn--border-dark pdl-hero__btn'
        target='_blank'
        onClick={onClickHandler}
      >
        {props.btnText}
      </a>
    </div>
  );
};

export default PdlHeroText;
