import React from 'react';
import * as Types from 'types';

import TextBlock from 'components/TextBlock';
import { AnimatedCarousel } from 'components/carousel/AnimatedCarousel';

export interface Props {
  id: string;
  smallHeading: string;
  heading: string;
  textWidth: 'narrow' | 'regular' | 'wide';
  initialArtworks?: Types.Artwork[];
  initialArtists?: Types.Artist[];
  staggered?: boolean;
}

const PdlCarousel = ({ textWidth = 'regular', ...props }: Props) => {
  return (
    <>
      <div className='pdl-carousel'>
        <TextBlock
          className='pdl-carousel__text'
          size='sm'
          alignment='center'
          width={textWidth}
          heading={props.heading}
          smallHeading={props.smallHeading}
        />
        <AnimatedCarousel
          id={props.id}
          initialArtworks={props.initialArtworks}
          initialArtists={props.initialArtists}
          itemWidth={props.initialArtists ? 160 : 260}
          itemGutter={props.initialArtists ? 24 : 48}
          staggered={props.staggered}
        />
      </div>
    </>
  );
};

export default PdlCarousel;
