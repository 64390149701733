import React from 'react';
import classNames from 'classnames';

import * as Types from 'types';

type Props = {
  children: React.ReactNode;
} & Types.Styleable;

export const ArtworkThumbnailOverlay = (props: Props) => {
  const className = classNames(
    'absolute-overlay visible-on-hover',
    props.className,
  );

  return (
    <div style={props.style} className={className}>
      {props.children}
    </div>
  );
};
