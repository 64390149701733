import React, { FC } from 'react';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'google-cast-launcher': any;
    }
  }
}

type Props = {
  className?: string;
}

export const GoogleCastLauncher: FC<Props> = ({ className }) => (
  <div className={className}>
    <google-cast-launcher class='player__btn-cast'></google-cast-launcher>
  </div>
);
