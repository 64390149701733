import React from 'react';

export interface Props {
  title: string;
  content: string;
}

export const Accordion = ({ title, content }: Props) => {
  return (
    <article className='collapsable collapsable--dark-border'>
      <input
        className='collapsable__checkbox'
        type='checkbox'
        id={`collapsable__checkbox-${title}`}
      />
      <label
        htmlFor={`collapsable__checkbox-${title}`}
        className='collapsable__indicator icon-arrow-right'
        tabIndex={0}
      />
      <label htmlFor={`collapsable__checkbox-${title}`} className='collapsable__title' tabIndex={0}>
        {title}
      </label>
      <div className='collapsable__content' dangerouslySetInnerHTML={{ __html: content }}></div>
      <div className='collapsable__separator' />
    </article>
  );
};
