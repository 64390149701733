import React from 'react';

import * as Types from 'types';

import { PlaylistPlayer } from './components/PlaylistPlayer';
import { PlayerOptions, artworkToPlaylist } from './utils';

type Props = {
  artwork: Types.Artwork;
  options?: PlayerOptions;
};

export const ArtworkPlayer: React.FC<Props> = (props) => {
  return (
    <PlaylistPlayer
      playlist={artworkToPlaylist(props.artwork)}
      options={props.options}
    />
  );
};

export { artworkToPlaylist } from './utils';
export { PlaylistPlayer } from './components/PlaylistPlayer';
export { SeamlessPlayer } from './SeamlessPlayer';
