import { connect } from 'react-redux';

import { App } from 'apps/homepage/components/app/Component';
import { fetchArtwork } from 'apps/shared/features/artworks/actions';

const mapStateToProps = () => ({
  loggedIn: !!window.sedition.currentUser.id,
});

const mapDispatchToProps = {
  afterOpenModal: fetchArtwork,
};

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);
