import { connect } from 'react-redux';

import { HomepageArtworkPreviewModal, OwnProps } from 'components/artworkPreviewModal/Component';
import { closeModal, openModal } from 'apps/shared/features/modal/actions';
import { showModalCustomEvent } from 'utils/tracking/showModalCustomEvent';

import { HomepageRootState } from 'apps/homepage/store';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ArtworkType } from 'apps/shared/features/artworks/reducer';

export type ModalData = {
  type: ArtworkType;
  listing: string;
  artworkId: number;
};

const mapStateToProps = (
  { modal, artworks, currency }: HomepageRootState,
  { modalId }: OwnProps,
) => {
  const currentModal = modal.byId[modalId];

  if (!currentModal) {
    return {
      isOpen: false,
      artwork: undefined,
      prevArtworkId: null,
      nextArtworkId: null,
      currency,
      currentModalData: null,
    };
  }

  const { artworkId, type, listing } = currentModal?.data;

  const artworkList = artworks.byType[type].byId[listing].list;

  const artworkIndex = artworkList.findIndex(({ id }) => id === artworkId);

  const artwork = artworks?.byId?.[artworkId.toString(10)] || artworkList[artworkIndex];

  return {
    isOpen: modal.byId[modalId].isOpen,
    artwork,
    prevArtworkId: artworkList[artworkIndex - 1] ? artworkList[artworkIndex - 1].id : null,
    nextArtworkId: artworkList[artworkIndex + 1] ? artworkList[artworkIndex + 1].id : null,
    currency,
    currentModalData: currentModal ? currentModal.data : null,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<HomepageRootState, unknown, AnyAction>,
  { modalId }: OwnProps,
) => ({
  onClose: () => dispatch(closeModal({ modalId })),
  onChangeArtwork: (data: { artworkId: number }) => {
    dispatch(
      openModal({
        modalId,
        data,
        dataLayerPush: showModalCustomEvent('quick-view', {
          artworkId: data.artworkId,
        }),
      }),
    );
  },
});

export const ArtworkPreviewModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomepageArtworkPreviewModal);
