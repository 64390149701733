import React, { useState, useEffect } from 'react';
import { motion, useMotionValue } from 'framer-motion';

import { requestPreviewMediaById } from 'features/media/api';

import { VideoPlayer } from './components/VideoPlayer';

type Props = {
  artworkIds: number[];
  className?: string;
  onPlaybackAttempted?: (isPlaying: boolean) => void;
};

export const SeamlessPlayer: React.FC<Props> = ({ artworkIds, ...props }) => {
  // Transition controls
  const [opacity, setOpacity] = useState(0);
  const opacityMotion = useMotionValue(opacity);
  const [visible, setVisible] = useState(false);

  const [index, setIndex] = useState(0);
  const artworkId = artworkIds[index];
  const [url, setURL] = useState<undefined | string>();

  useEffect(() => {
    requestPreviewMediaById(artworkId).then((response) => {
      setURL(response.url);
    });
  }, [artworkId]);

  useEffect(() => {
    opacityMotion.onChange((opacity) => {
      setVisible(opacity > 0);
    });
  }, []);

  useEffect(() => {
    if (visible) {
      props.onPlaybackAttempted && props.onPlaybackAttempted(true);
    } else {
      // Next artwork when we are fully faded out.
      setIndex(index === artworkIds.length - 1 ? 0 : index + 1);
    }
  }, [visible]);

  if (artworkId === undefined) {
    return null;
  }

  return (
    <motion.div
      className={props.className}
      transition={{ duration: 0.7 }}
      animate={{ opacity }}
      // Note: this is actually a getter o.O
      style={{ opacity: opacityMotion }}
    >
      <VideoPlayer
        url={url}
        paused={false}
        onMediaLoadStateChange={(state) => {
          if (state === 'loaded') {
            setOpacity(1);
          }
        }}
        onAutoQualityChange={() => {}}
        onAboutToEnd={() => {
          setOpacity(0);
        }}
        onError={(error) => {
          console.error(error);
          props.onPlaybackAttempted && props.onPlaybackAttempted(false);
        }}
        volume={0}
        onVolumeChanged={() => {}}
        jwplayerOptions={{ stretching: 'fill' }}
      />
    </motion.div>
  );
};
