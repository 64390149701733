import React from 'react';
import { Spinner } from 'components/Spinner';

import * as Types from 'types';

type Props = {
  playlistName: Types.Playlist['name'];
  onClick: (event: React.SyntheticEvent) => void;
  icon?: 'loading' | 'checkmark';
  onMessageFadeOut?: () => void;
};

export const PlaylistWidgetPlaylistItem: React.FC<Props> = (props) => (
  <a className='px-1 py-mini d-block pos-rel' href='#' onClick={props.onClick}>
    <span className='d-flex align-items-center'>
      <span className='flex-grow-1'>{props.playlistName}</span>
      {props.icon === 'checkmark' && (
        <span title='Already in playlist' className='icon-checkmark' />
      )}
      {props.icon === 'loading' && <Spinner className='square-spin--light square-spin--xs' />}
    </span>
  </a>
);
