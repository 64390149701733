import { sprintf } from 'sprintf';

import * as Types from 'types';

import { WIDTH_4K } from 'constants/global';

export const getArtworkEditionDescription = (totalEditions?: number, isNft?: boolean) => {
  return isNft
    ? sprintf('Edition of %s', totalEditions)
    : sprintf('Digital edition of %s', totalEditions);
};

export const getArtworkBundleTitle = (artwork: Types.ArtworkDetails) => {
  const bundledObjects = artwork.bundledPhysicalObjects;

  if (bundledObjects.length) {
    return `+ ${bundledObjects.map((physicalObject) => physicalObject.title).join(' + ')}`;
  }

  return '';
};

export const isArtworkAvailableViaArtStream = (artwork: Types.Artwork) =>
  !artwork.soldOut && artwork.forSale;

export const getResolutionLabelByWidth = (
  width: Types.ArtworkResolution['width'] = 0,
): Types.ArtworkResolutionLabel =>
  width >= WIDTH_4K ? Types.ArtworkResolutionLabel['4K'] : Types.ArtworkResolutionLabel['HD'];
