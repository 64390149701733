import { ActionType } from 'typesafe-actions';

import { MediaActionTypes, MediaState } from './types';

export const INITIAL_STATE: MediaState = {
  previews: {},
};

import { Actions } from './actions';
export type MediaAction = ActionType<typeof Actions>;

export const mediaReducer = (
  state = INITIAL_STATE,
  action: MediaAction,
): MediaState => {
  switch (action.type) {
    case MediaActionTypes.RECEIVE_PREVIEW:
      return {
        ...state,
        previews: {
          ...state.previews,
          [action.payload.artworkId]: action.payload,
        },
      };

    default:
      return state;
  }
};
