import React, { FC, useEffect, useState } from 'react';
import { ControlWithOptions } from './ControlWithOptions';
import * as Types from 'types';
import classnames from 'classnames';
import { useChromecast } from 'hooks/useChromecast';
import { ChromecastSettings } from 'context/ChromecastContext/types';

const SETTINGS_CONFIG = [
  {
    title: 'Orientation',
    propName: 'screenOrientation',
    options: [
      { title: 'Landscape', propValue: Types.ScreenOrientationOptions.LANDSCAPE },
      { title: 'Portrait', propValue: Types.ScreenOrientationOptions.PORTRAIT },
    ],
  },
  {
    title: 'Show Title',
    propName: 'showTitle',
    options: [
      { title: 'Off', propValue: Types.ShowTitleOptions.OFF },
      { title: '5 seconds', propValue: Types.ShowTitleOptions.WITH_TIMEOUT },
      { title: 'Always', propValue: Types.ShowTitleOptions.ON },
    ],
  },
];

export const Settings = () => {
  const { player, settings } = useChromecast();
  const [subMenu, setSubMenu] = useState<number | undefined>();

  const handleClick = (setting: Partial<ChromecastSettings>) => {
    settings && player?.setReceiverSettings({ ...settings, ...setting });
  };

  return (
    <>
      <ControlWithOptions
        optionsElement={() => (
          <div className='player__btn-options__wrapper'>
            <div className='player__btn-options player__btn-options--select'>
              {subMenu === undefined &&
                SETTINGS_CONFIG.map((item, index) => (
                  <Option
                    key={item.title}
                    title={item.title}
                    onClick={() => setSubMenu(index)}
                    className='pr-mini'
                    withIcon
                  />
                ))}
              {subMenu !== undefined && (
                <>
                  <div
                    className='player__btn-options__option py-mini'
                    onClick={() => setSubMenu(undefined)}
                  >
                    <span className='icon-left mr-mini' />
                    {SETTINGS_CONFIG[subMenu].title}
                  </div>
                  {SETTINGS_CONFIG[subMenu]?.options?.map((item) => (
                    <Option
                      key={item.title}
                      title={item.title}
                      onClick={() =>
                        handleClick({ [SETTINGS_CONFIG[subMenu].propName]: item.propValue })
                      }
                      className={classnames({
                        'player__btn-options__option--selected':
                          item.propValue === settings?.showTitle ||
                          item.propValue === settings?.screenOrientation,
                      })}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
        )}
        buttonElement={() => (
          <div className='player__btn player__btn--icon player__btn--quality player__btn--with-options icon-support-accounts-and-settings' />
        )}
      />
    </>
  );
};

type OptionProps = {
  title: string;
  className?: string;
  withIcon?: boolean;
  onClick?: () => void;
};

const Option: FC<OptionProps> = ({ title, onClick, withIcon, className }) => (
  <div
    className={`d-flex justify-content-between player__btn-options__option ${className}`}
    onClick={onClick}
  >
    {title}
    {withIcon && <span className='icon-right' />}
  </div>
);
