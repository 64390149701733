import React from 'react';

export interface Props {
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  alignment?: 'left' | 'center';
  textColour?: 'white' | 'grey' | 'black';
  width?: 'narrow' | 'regular' | 'wide';
  heading: string;
  smallHeading?: string;
  text?: string | Array<string>;
  text2?: string;
  buttonText?: string;
  buttonClass?: string;
  buttonHref?: string;
  onClickHandler?: () => void;
}

const TextBlock = ({ size = 'md', alignment = 'left', textColour = 'black', ...props }: Props) => {
  // Splits heading into multiple lines if there are commas
  const heading = props.heading.split(', ').map((part, index) => (
    <React.Fragment key={index}>
      {index > 0 && (
        <>
          ,<br />
        </>
      )}
      {part}
    </React.Fragment>
  ));

  return (
    <div
      className={`text-${alignment} text-block--${textColour} ${
        props.width ? `text-block--${props.width}` : null
      } ${props.className}`}
    >
      {size === 'lg' ? (
        <h1 className='text-block__large-title--lg'>{heading}</h1>
      ) : (
        <>
          {props.smallHeading ? (
            <h4 className='text-block__small-title'>{props.smallHeading}</h4>
          ) : null}
          <h3 className={`text-block__large-title--${size}`}>{heading}</h3>
        </>
      )}
      {props.text ? (
        props.text.constructor !== Array ? (
          <p className='text-block__text'>{props.text}</p>
        ) : (
          <ul>
            {props.text.map((text, index) => (
              <li className='text-block__text' key={index}>
                {text}
              </li>
            ))}
          </ul>
        )
      ) : null}
      {props.text2 ? <p className='text-block__text'>{props.text2}</p> : null}
      {props.buttonText && props.buttonHref ? (
        <a className={`btn ${props.buttonClass}`} href={props.buttonHref}>
          {props.buttonText}
        </a>
      ) : props.buttonText && props.onClickHandler ? (
        <a className={`btn ${props.buttonClass}`} onClick={props.onClickHandler}>
          {props.buttonText}
        </a>
      ) : null}
    </div>
  );
};

export default TextBlock;

{
  /* <td>
  <img src='{{bidder_picture}}' />
  <a href='{{bidder_url}}'> {{bidder_name}} </a>
</td>
<td> {{date}} </td>
<td class='bid-col'>
  <span> {{bid_amount}} </span>
</td>
<td> {{#if highest}}
  <button class='btn accept-bid bg-black btn--medium btn-trade-table js-otp-verification--sell'> Accept bid </button>
  <div class='js-two-factor-auth-container'></div>
  {{/if}}
  </td>

  ?</strong> Commission </li>
  <li class='profit-li'> <strong class='profit'>?</strong> Your profit </li>
  </ul>
  </div>
  </div>

  <div class='js-two-factor-auth-container'></div> */
}
