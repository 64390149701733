import * as Types from 'types';

export enum PlaylistsActionTypes {
  RECEIVE_PLAYLISTS = '@playlists/RECEIVE_PLAYLISTS',
  SET_CURRENT_PLAYLIST = '@playlists/SET_CURRENT_PLAYLIST',
  SET_PLAYLISTS = '@playlists/SET_PLAYLISTS',
  RESET_PLAYLISTS = '@playlists/RESET_PLAYLISTS',
  REMOVE_PLAYLIST = '@playlists/REMOVE_PLAYLIST',
  SET_ADD_ARTWORK_OPEN = '@playlists/SET_ADD_ARTWORK_OPEN',
  SET_ART_STREAM_ARTWORKS_FILTER = '@playlists/SET_ART_STREAM_ARTWORKS_FILTER',
  SET_ART_STREAM_ARTWORKS_FETCHING = '@playlists/SET_ART_STREAM_ARTWORKS_FETCHING',
  START_FETCHING = '@playlists/START_FETCHING',
}

export interface PlaylistsState {
  readonly currentActiveItemId?: string;
  readonly activePlaylistId?: string;
  readonly isFetching: boolean;
  readonly isAddArtworkOpen: boolean;
  readonly current?: Types.Playlist;
  readonly byId: {
    [id: string]: Types.Playlist;
  };
  readonly artStreamArtworksFilter: string;
  readonly artStreamArtworksFetching: boolean;
}
