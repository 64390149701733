import { request } from 'utils/request';

import * as Types from 'types';

import { PlaylistResponse, mapPlaylistResponseToPlaylist } from './index';

export const requestPublicPlaylists = (): Promise<Types.Playlist[]> =>
  request
    .get<PlaylistResponse[]>(`/api/internal/playlists.json`)
    .then(({ data }) => data.map(mapPlaylistResponseToPlaylist));
