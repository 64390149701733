import React from 'react';

type Props = {
  message: string;
};

export const LoadingSpinner: React.FC<Props> = ({ message }) => (
  <div className='col-auto d-flex justify-content-center mt-3 mb-3'>
    <div className='square-spin square-spin--dark square-spin--small' />
    <div className='key-value key-value__value ml-1' style={{ marginTop: '3px' }}>
      {message}
    </div>
  </div>
);
