import { Listing } from 'types';
import { ArtworkListing } from 'apps/shared/features/artworks/reducer';

export const constructListings = (state: any, listings: Listing[]) => ({
  ...state,
  byId: listings.reduce<Record<string, ArtworkListing>>((acc, cur) => {
    return {
      ...acc,
      [cur.id]: { isFetching: false, list: [], endOfResults: false, currentPage: 0 },
    };
  }, {}),
});
