import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as Types from 'types';

import { requestFollow, requestUnfollow } from 'features/followings/api';
import {
  receiveUserFollowings,
  receivePlaylistFollowings,
} from 'features/currentUser/actions';

const followedUsersSelector = (state: Types.RootState) => {
  return state.currentUser.followedUsers || [];
};

const followedPlaylistSelector = (state: Types.RootState) => {
  return state.currentUser.followedPlaylists || [];
};

export const useFollowings = (
  entityId: number,
  type: Types.FollowableType,
): {
  isFollowing: boolean;
  follow: (entityId: number) => void;
  unfollow: (entityId: number) => void;
} => {
  const followedEntities = useSelector(
    type === 'Playlist' ? followedPlaylistSelector : followedUsersSelector,
  );

  const isFollowing = followedEntities.includes(entityId);
  const dispatch = useDispatch();

  const follow = useCallback(
    (entityId: number) => {
      if (!window.sedition.currentUser.id) {
        return;
      }
      if (followedEntities.includes(entityId)) {
        return;
      }
      const newFollowings = [...followedEntities, entityId];
      if (type === 'Playlist') {
        dispatch(receivePlaylistFollowings(newFollowings));
      } else if (type === 'User') {
        dispatch(receiveUserFollowings(newFollowings));
      }
      requestFollow(window.sedition.currentUser.id, entityId, type).catch(
        () => {
          // Revert on error
          const oldFollowings = followedEntities.filter(
            (id) => entityId !== id,
          );
          if (type === 'Playlist') {
            dispatch(receivePlaylistFollowings(oldFollowings));
          } else if (type === 'User') {
            dispatch(receiveUserFollowings(oldFollowings));
          }
        },
      );
    },
    [dispatch, followedEntities],
  );

  const unfollow = useCallback(
    (entityId: number) => {
      if (!window.sedition.currentUser.id) {
        return;
      }
      if (!followedEntities.includes(entityId)) {
        return;
      }
      const newFollowings = followedEntities.filter((id) => id !== entityId);
      if (type === 'Playlist') {
        dispatch(receivePlaylistFollowings(newFollowings));
      } else if (type === 'User') {
        dispatch(receiveUserFollowings(newFollowings));
      }
      requestUnfollow(window.sedition.currentUser.id, entityId, type);
    },
    [dispatch, followedEntities],
  );

  return {
    isFollowing,
    follow,
    unfollow,
  };
};
