import { connect } from 'react-redux';

import { ArtworksGrid } from 'components/artworksGrid/Component';
import { fetchArtworks } from 'apps/shared/features/artworks/actions';
import { openModal } from 'apps/shared/features/modal/actions';

import { HomepageRootState } from 'apps/homepage/store';
import { ArtworkType } from 'apps/shared/features/artworks/reducer';

const mapStateToProps = (state: HomepageRootState, { type }: { type: ArtworkType }) => {
  const { currentListing } = state.artworks;

  const listingsExists = Object.keys(state.artworks.byType[type].byId).length > 0;

  const currentListingExists =
    listingsExists && currentListing && state.artworks.byType[type].byId[currentListing];

  const isFetching = currentListingExists
    ? state.artworks.byType[type].byId[currentListing].isFetching
    : false;

  return {
    currency: state.currency,
    perPage: state.artworks.byType[type].perPage,
    currentListing,
    isVisible: state.artworks.byType[type].byId[currentListing]?.list?.length > 0 || isFetching,
    canLoadMore: !state.artworks.byType[type].byId[currentListing]?.endOfResults,
    artworks: currentListingExists ? state.artworks.byType[type].byId[currentListing]?.list : [],
    isFetching,
  };
};

const mapDispatchToProps = {
  onRequestArtworks: fetchArtworks,
  onClickPreview: openModal,
};

export const ArtworksGridContainer = connect(mapStateToProps, mapDispatchToProps)(ArtworksGrid);
