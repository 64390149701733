import React from 'react';

import { FollowUserButton } from 'components/users/FollowUserButton';
import { Avatar } from 'components/users/Avatar';

type Props = {
  userId: number;
  name: string;
  url: string;
  avatarUrl?: string;
};

export const Header: React.FC<Props> = ({ userId, name, url, avatarUrl }) => {
  return (
    <header className='container-fluid my-1 my-lg-2 px-lg-2'>
      <div className='row'>
        <div className='pl-1'>
          <Avatar url={url} avatarUrl={avatarUrl} name={name} size='smaller' className='mr-1 vam' />
        </div>
        <div className='col-auto pl-0 d-flex flex-column justify-content-between mh-inherit'>
          <h1 className='d-inline-block fs-16 lh-1 pos-rel' style={{ top: 2 }}>
            <a className='artwork-header__name' href={url}>
              {name}
            </a>
          </h1>
          <FollowUserButton userId={userId} />
        </div>
      </div>
    </header>
  );
};
