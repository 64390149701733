import React, { useEffect, useRef, createRef, useState } from 'react';

import { MediaPlayerProps } from '../utils';
import {
  Quality,
  QualityLevel,
  qualityToIndex,
  jwLevelsToQualityLevels,
  JWQualityLevel,
} from '../quality';
import { VOLUME } from 'artworkPlayer/config';

type Props = MediaPlayerProps & {
  volume?: number;
  mute?: boolean;
  quality?: Quality;
  loop?: boolean;
  jwplayerOptions?: {
    stretching?: 'uniform' | 'exactfit' | 'fill' | 'none';
  };
  stopped?: boolean;
  onAutoQualityChange: (quality: Quality) => void;
  onQualityLevels?: (levels: QualityLevel[]) => void;
  onVolumeChanged: (volume: number) => void;
  onPlayAttemptFailed?: (player: jwplayer.JWPlayer) => void;
  onAboutToEnd?: () => void;
};

type TimeEventMetrics = {
  count: number;
  lastPosition: number;
  average: number;
};

const INITIAL_TIME_METRICS = {
  count: 0,
  lastPosition: 0,
  average: 0,
};

export const VideoPlayer: React.FC<Props> = ({
  url,
  paused,
  volume,
  quality,
  loop,
  jwplayerOptions,
  onMediaLoadStateChange,
  onAutoQualityChange,
  onQualityLevels,
  onVolumeChanged,
  onError,
  onEnded,
  onAboutToEnd,
  mute = false,
  stopped = false,
  onPlayAttemptFailed,
}) => {
  const ref = createRef<HTMLDivElement>();
  const jwRef = useRef<jwplayer.JWPlayer>();
  const timeRef = useRef<TimeEventMetrics>(INITIAL_TIME_METRICS);
  const loopRef = useRef(loop);
  const shouldRetryPlayAttemptRef = useRef(true);
  const aboutToEndTriggeredRef = useRef(false);

  const [error, setError] = useState<string>();

  loopRef.current = loop;

  const errorEventHandler = (event: jwplayer.ErrorParam): void => {
    console.error(event);
    onError ? onError(event.message) : setError(event.message);
  };

  useEffect(() => {
    if (error) {
      throw new Error(error);
    }
  }, [error]);

  useEffect(() => {
    stopped && jwRef.current?.stop();
  }, [stopped]);

  useEffect(() => {
    return () => {
      jwRef.current?.stop();
      jwRef.current?.remove();
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      jwRef.current = jwplayer(ref.current);
    }
  }, [ref.current]);

  useEffect(() => {
    const jw = jwRef.current;
    volume !== undefined && jw?.setVolume(volume);
  }, [volume]);

  useEffect(() => {
    const jw = jwRef.current;
    jw?.setMute(mute);
  }, [mute]);

  useEffect(() => {
    const jw = jwRef.current;
    if (!jw) {
      throw 'JWPlayer is not initialized';
    }

    shouldRetryPlayAttemptRef.current = true;

    if (url) {
      const config = {
        controls: false,
        width: '100%',
        height: '100%',
        volume: volume,
        sources: [
          {
            file: url,
          },
        ],
        mute: mute,
        autostart: false,
        ...jwplayerOptions,
      };

      jw.setup(config);
      jw.on('setupError', errorEventHandler);
      jw.on('error', errorEventHandler);

      jw.on('ready', () => {
        onMediaLoadStateChange('loaded');
      });

      jw.on('visualQuality', (event) => {
        const level = (event as any).level as JWQualityLevel & {
          index: number;
        };
        const qualityLevels = jwLevelsToQualityLevels(jw.getQualityLevels());
        const currentLevel = qualityLevels.find((item) => item.index === level.index);
        // console.log('Visual quality', event, currentLevel);
        if (currentLevel) {
          onAutoQualityChange(currentLevel.label);
        }
      });

      jw.on('levels', (event) => {
        const levels = event.levels as JWQualityLevel[];
        // console.log('Levels received', event.levels);
        onQualityLevels && onQualityLevels(jwLevelsToQualityLevels(levels));
        if (quality) {
          // console.log(
          //   'Setting quality to',
          //   quality,
          //   qualityToIndex(quality, event.levels),
          // );
          jw.setCurrentQuality(qualityToIndex(quality, event.levels));
        }
      });

      jw.on('beforePlay', () => {
        aboutToEndTriggeredRef.current = false;
      });

      jw.on('time', (event) => {
        const { average, count, lastPosition } = timeRef.current;
        const delta = event.position - lastPosition;

        timeRef.current = {
          average: (average * count + delta) / (count + 1),
          lastPosition: event.position,
          count: count + 1,
        };

        if (onAboutToEnd && !aboutToEndTriggeredRef.current) {
          if (event.duration - event.position < 4) {
            // Ensure this is triggered only once
            aboutToEndTriggeredRef.current = true;
            onAboutToEnd();
          }
        }

        /**
         * JWPlayer repeat is not a seamless process. Moreover
         * it will make the browser downlaod the video again and again!
         *
         * We cannot do e.position == e.duration because 'time' event is
         * unpredictable so we calculate average firing and then
         * repeat when we are by 3 'time' events till the end of video.
         *
         * Would be nice to add an option for fade in/out for videos
         * that are not looping nicely.
         */
        if (event.position > event.duration - timeRef.current.average * 3) {
          if (loopRef.current) {
            jw.seek(0);
            aboutToEndTriggeredRef.current = false;
            timeRef.current = INITIAL_TIME_METRICS;
          }
        }
      });

      // @ts-ignore
      jw.on('playAttemptFailed', (error) => {
        onPlayAttemptFailed?.(jw);
        console.log(error);
      });

      jw.on('complete', () => {
        timeRef.current = INITIAL_TIME_METRICS;
        jw.stop();
        onEnded?.();
      });
    } else {
      if (jw && jw.getPlaylistItem()) {
        jw.stop();
        jw.remove();
      }
    }
  }, [url]);

  useEffect(() => {
    const jw = jwRef.current;

    if (!jw) {
      throw 'JWPlayer is not initialized';
    }

    if (!url) {
      return;
    }

    if (stopped) {
      return;
    }

    switch (true) {
      case jw?.getState() === 'playing' && paused:
        jw.pause();
        break;
      case jw?.getState() === 'idle' && !paused:
      case jw?.getState() === 'paused' && !paused:
        jw.play();
        break;
    }
  }, [paused, stopped, url]);

  useEffect(() => {
    const jw = jwRef.current;
    if (jw && quality && jw.getQualityLevels()) {
      jw.setCurrentQuality(qualityToIndex(quality, jw.getQualityLevels()));
    }
  }, [quality]);

  return (
    <div style={{ height: '100%' }}>
      <div ref={ref} />
    </div>
  );
};
