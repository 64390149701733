import React, { useEffect, useReducer, useRef } from 'react';
import {
  getChromecastInstance,
  CUSTOM_CHANNEL,
  ChromecastControllerType,
  ChromecastPlayer,
} from 'artworkPlayer/chromecast';

import { ChromecastContextValue, ChromecastState, ActionKind } from './types';
import { reducer } from './reducer';

const defaultValue = {
  available: undefined,
  connected: undefined,
  currentArtwork: undefined,
  receiverPlaybackState: undefined,
  settings: { showTitle: undefined, screenOrientation: undefined },
};

const initialState: ChromecastState = {
  available: false,
  connected: false,
  currentArtwork: undefined,
  receiverPlaybackState: undefined,
  settings: { showTitle: undefined, screenOrientation: undefined },
};

export const ChromecastContext = React.createContext<ChromecastContextValue>(defaultValue);

export const ChromecastProvider: React.FC<ChromecastContextValue> = (props) => {
  const chromecastRef = useRef<ChromecastControllerType>();
  const chromecastPlayerRef = useRef<ChromecastPlayer>();

  const [chromecastState, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const chromecastController = getChromecastInstance();

    chromecastRef.current = chromecastController;

    const handleCastStateChange = ({ castState }: cast.framework.CastStateEventData) => {
      dispatch({
        type: ActionKind.UPDATE_DEVICE_STATE,
        payload: castState !== cast.framework.CastState.NO_DEVICES_AVAILABLE,
      });
    };

    chromecastController.ready.then((player) => {
      /**
       * FF doesn't support cast,
       * the cast global variable isn't instantiated by sender lib.
       */
      if (typeof cast === 'undefined') {
        return;
      }

      chromecastPlayerRef.current = player;

      const castContext = cast.framework.CastContext.getInstance();

      dispatch({
        type: ActionKind.UPDATE_SESSION_STATE,
        payload: player.isConnected,
      });

      player.on('connected', (isConnected) => {
        dispatch({
          type: ActionKind.UPDATE_SESSION_STATE,
          payload: isConnected,
        });
      });

      castContext.addEventListener(
        cast.framework.CastContextEventType.CAST_STATE_CHANGED,
        handleCastStateChange,
      );
    });

    return () => {
      chromecastPlayerRef.current = undefined;

      if (typeof cast === 'undefined') {
        return;
      }

      const castContext = cast.framework.CastContext.getInstance();

      castContext.removeEventListener(
        cast.framework.CastContextEventType.CAST_STATE_CHANGED,
        handleCastStateChange,
      );
    };
  }, []);

  useEffect(() => {
    if (!chromecastState.connected) {
      return;
    }
    const session = cast.framework.CastContext.getInstance().getCurrentSession();

    session?.addMessageListener(CUSTOM_CHANNEL, (_, message) => {
      dispatch(JSON.parse(message));
    });
  }, [chromecastState.connected]);

  return (
    <ChromecastContext.Provider
      {...props}
      value={{
        ...chromecastState,
        player: chromecastPlayerRef.current,
      }}
    />
  );
};
