import { handleActions } from 'redux-actions';

import { WISHLIST_ADD, WISHLIST_REMOVE } from 'apps/shared/features/wishList/constants';

type WishlistReducerShape = {
  byId: Record<string, string>;
};

type WishlistReducerPayload = {
  wishId: string;
  artworkId: string;
};

const defaultState = {
  byId: {},
};

export const wishListReducer = handleActions<WishlistReducerShape, WishlistReducerPayload>(
  {
    [WISHLIST_ADD]: (state, { payload }) => ({
      ...state,
      byId: {
        ...state.byId,
        [payload.artworkId]: payload.wishId,
      },
    }),
    [WISHLIST_REMOVE]: (state, { payload }) => ({
      ...state,
      byId: Object.keys(state.byId).reduce<Record<string, string>>((acc, cur) => {
        if (cur !== String(payload.artworkId)) {
          acc[cur] = state.byId[cur];
        }
        return acc;
      }, {}),
    }),
  },
  defaultState,
);
