import React from 'react';

import { Accordion } from 'components/Accordion';
import { sprintf } from 'sprintf';
import { PDL_FAQS } from '../pdlContent';

const FAQs = () => {
  return (
    <section className='pdl-faqs'>
      <div className='pdl-faqs__questions'>
        <h2 className='pdl-faqs__title'>Frequently Asked Questions</h2>
        {PDL_FAQS.map(({ title, content }) => (
          <Accordion title={title} key={`item-${title}`} content={content} />
        ))}
      </div>
      <div>
        <p
          className='pdl-faqs__more-info'
          dangerouslySetInnerHTML={{
            __html: sprintf(
              `For more information visit our %s`,
              `<a href="${Routes.learn_more_index_path()}/public-displays" class="pdl-faqs__link"  target="_blank">Help Centre</a>`,
            ),
          }}
        ></p>
      </div>
    </section>
  );
};

export default FAQs;
