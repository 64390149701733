import React, { FC, useEffect } from 'react';
import { sprintf } from 'sprintf';

import { Artwork as ArtworkComponent } from 'apps/discover/components/artworks/Artwork';
import { GridButton } from 'apps/homepage/components/GridButton';
import { ArtworkPlaceholder } from 'components/artworks/ArtworkPlaceholder';
import { MODAL_ID } from 'apps/homepage/utils/modal';
import { LoadingSpinner } from 'apps/discover/components/LoadingSpinner';
import { showModalCustomEvent } from 'utils/tracking/showModalCustomEvent';

import * as Types from 'types';

import { Currency } from 'services/stringFormating';
import { ArtworkType } from 'apps/shared/features/artworks/reducer';
import { ModalData } from 'components/artworkPreviewModal/Container';

const isQuickArtworkPreviewEnabled = true;

type OwnProps = {
  artworks: Types.LegacyArtwork[];
  currentListing: string;
  canLoadMore: boolean;
  isFetching: boolean;
  isVisible: boolean;
  perPage: number;
  currency: Currency | null;
  type: ArtworkType;
};

type StateProps = {
  currency: Currency | null;
  perPage: number;
  currentListing: string;
  isVisible: boolean;
  canLoadMore: boolean;
  artworks: Types.LegacyArtwork[];
  isFetching: boolean;
};

type OnClickPreviewParamaters = { modalId: string; data: ModalData; dataLayerPush: () => void };

type DispatchProps = {
  onRequestArtworks: (listingId: string, loadMore?: boolean, type?: ArtworkType) => void;
  onClickPreview: (param: OnClickPreviewParamaters) => void;
};

type Props = StateProps & OwnProps & DispatchProps;

export const ArtworksGrid: FC<Props> = ({
  currentListing,
  canLoadMore = true,
  onRequestArtworks,
  perPage,
  isFetching,
  currency,
  onClickPreview,
  isVisible,
  children,
  type,
  artworks,
}) => {
  useEffect(() => {
    currentListing && onRequestArtworks(currentListing, false, type);
  }, [currentListing]);

  return (
    <div style={{ display: isVisible ? 'block' : 'none' }}>
      {children}
      <div className='row'>
        {artworks.length > 0
          ? artworks.map((artwork) =>
              artwork ? (
                <ArtworkComponent
                  hideWishList
                  key={artwork.id}
                  id={artwork.id}
                  //@ts-ignore
                  thumbnail={artwork.thumbnail}
                  title={artwork.title}
                  url={artwork.url}
                  artistUrl={artwork.artist_url}
                  currentPrice={currency ? artwork?.current_prices?.[currency] : undefined}
                  artistName={artwork.artist_name}
                  forSale={Boolean(artwork.for_sale)}
                  soldOut={Boolean(artwork.sold_out)}
                  isMintable={Boolean(artwork.nft)}
                  auctionState={artwork.auction_state}
                  multipleIterations={Boolean(artwork.children_count > 0)}
                  onClickPreview={() =>
                    isQuickArtworkPreviewEnabled &&
                    onClickPreview({
                      modalId: MODAL_ID,
                      data: {
                        type,
                        listing: currentListing,
                        artworkId: artwork.id,
                      },
                      dataLayerPush: showModalCustomEvent('quick-view', {
                        artworkId: artwork.id,
                      }),
                    })
                  }
                />
              ) : null,
            )
          : Array(...Array(9)).map((key, index) => (
              <ArtworkPlaceholder
                className='mb-gutter col-12 col-lg-4 col-md-4 col-sm-6'
                key={`${key}_${index}`}
              />
            ))}
      </div>
      {isFetching && (
        <div className='w-100 d-flex justify-content-center'>
          <LoadingSpinner message='Loading Artworks' />
        </div>
      )}
      {canLoadMore && (
        <div className='row mt-1 mb-2'>
          <div className='col-12 justify-content-center d-flex'>
            <GridButton
              onClick={() => onRequestArtworks(currentListing, true, type)}
              label={sprintf(
                'Load %s More %s artworks',
                perPage,
                type === 'curated' ? 'Curated' : 'Open Platform',
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
};
