import React, { FC, useEffect } from 'react';

import { useLoadImage } from 'hooks/useLoadImage';
import { useInterval } from 'hooks/useInterval';

import { MediaPlayerProps } from '../utils';

type Props = MediaPlayerProps;

export const ImagePlayer: FC<Props> = ({
  url,
  paused,
  onEnded,
  onMediaLoadStateChange,
}) => {
  const isImageLoaded = useLoadImage(url);

  useEffect(() => {
    isImageLoaded && onMediaLoadStateChange('loaded');
  }, [isImageLoaded]);

  return isImageLoaded && url ? (
    <Image url={url} paused={paused} onEnded={onEnded} />
  ) : null;
};

type ImageProps = {
  url: string;
} & Partial<Props>;

const PLAYBACK_DURATION = 30;

const Image: FC<ImageProps> = ({ url, paused, onEnded }) => {
  const { pause, resume } = useInterval({
    duration: PLAYBACK_DURATION,
    onComplete: onEnded,
  });

  useEffect(() => {
    paused ? pause() : resume();
  }, [paused]);

  return (
    <div
      className='player__image-player'
      style={{ backgroundImage: `url(${url})` }}
    />
  );
};
