import React from 'react';

import * as Types from 'types';

type Props = {
  artist: Types.Artist;
  className: string;
};

export const Artist: React.FC<Props> = ({ artist, ...props }) => {
  return (
    <div className={`${props.className} animated-carousel__item--round`}>
      <a className='animated-carousel__item-link' href={artist.url} target='_blank'>
        <img src={artist.avatar_url} />
      </a>
      <a href={artist.url} className='animated-carousel__overlay--round' target='_blank'></a>

      <a href={artist.url} target='_blank'>
        <p>{artist.name}</p>
      </a>
    </div>
  );
};
