import { handleActions } from 'redux-actions';
import { orderListings } from 'apps/homepage/utils/orderListings';
import { constructListings } from 'apps/homepage/utils/constructListings';

import {
  FETCH_LISTINGS,
  FETCH_ARTWORKS,
  RECEIVE_LISTINGS,
  RECEIVE_ARTWORKS,
  RECEIVE_MORE_ARTWORKS,
  CHANGE_CURRENT_LISTING,
  RECEIVE_ARTWORK,
  SET_END_OF_RESULTS,
} from 'apps/shared/features/artworks/constants';

import { LegacyArtwork, Listing } from 'types';

const defaultState = {
  byId: {},
  listings: [],
  type: null,
  currentListing: '',
  byType: {
    curated: {
      perPage: 9,
      isFetching: false,
      byId: {},
    },
    op: {
      perPage: 3,
      isFetching: false,
      byId: {},
    },
  },
};

export type ArtworkType = 'curated' | 'op';

export type ArtworkListing = {
  isFetching: boolean;
  endOfResults: boolean;
  list: LegacyArtwork[];
  currentPage: number;
};

type ArtworksReducerShape = {
  byId?: Record<string, LegacyArtwork>;
  type: ArtworkType | null;
  currentListing: string;
  listings: Listing[];
  byType: Record<
    'curated' | 'op',
    {
      perPage: number;
      isFetching: boolean;
      byId: Record<string, ArtworkListing>;
    }
  >;
};

type ArtworksReducerPayload = {
  type: 'curated' | 'op';
  id: string;
  curated: any;
  listings: Listing[];
  artworks: LegacyArtwork[];
  currentPage: number;
  endOfResults: boolean;
  artwork: LegacyArtwork;
};

export const artworksReducer = handleActions<ArtworksReducerShape, ArtworksReducerPayload>(
  {
    [SET_END_OF_RESULTS]: (state, { payload }) => ({
      ...state,
      [payload.type]: {
        ...state.byType[payload.type],
        byId: {
          ...state.byType[payload.type].byId,
          [payload.id]: {
            ...state.byType[payload.type].byId[payload.id],
            endOfResults: true,
            isFetching: false,
          },
        },
      },
    }),
    [FETCH_LISTINGS]: (state) => ({
      ...state,
      byType: {
        curated: { ...state.byType.curated, isFetching: true },
        op: { ...state.byType.op, isFetching: true },
      },
    }),
    [FETCH_ARTWORKS]: (state, { payload }) => ({
      ...state,
      byType: {
        ...state.byType,
        [payload.type]: {
          ...state.byType[payload.type],
          byId: {
            ...state.byType[payload.type].byId,
            [payload.id]: {
              ...state.byType[payload.type].byId[payload.id],
              isFetching: true,
            },
          },
        },
      },
    }),
    [RECEIVE_LISTINGS]: (state, { payload }) => ({
      ...state,
      currentListing: orderListings(payload.listings)[0].id,
      listings: payload.listings,
      byType: {
        curated: constructListings(state.byType.curated, payload.listings),
        op: constructListings(state.byType.op, payload.listings),
      },
    }),
    [RECEIVE_ARTWORKS]: (state, { payload }) => ({
      ...state,
      byId: {
        ...state.byId,
        ...payload.artworks.reduce<Record<string, LegacyArtwork>>(
          (accumulator, artwork) => ({ ...accumulator, [artwork.id]: artwork }),
          {},
        ),
      },
      byType: {
        ...state.byType,
        [payload.type]: {
          ...state.byType[payload.type],
          isFetching: false,
          byId: {
            ...state.byType[payload.type].byId,
            [payload.id]: {
              ...state.byType[payload.type].byId[payload.id],
              list: payload.artworks,
              currentPage: payload.currentPage,
              endOfResults: payload.endOfResults,
              isFetching: false,
            },
          },
        },
      },
    }),
    [RECEIVE_MORE_ARTWORKS]: (state, { payload }) => ({
      ...state,
      byType: {
        ...state.byType,
        [payload.type]: {
          ...state.byType[payload.type],
          isFetching: false,
          byId: {
            ...state.byType[payload.type].byId,
            [payload.id]: {
              ...state.byType[payload.type].byId[payload.id],
              list: [...state.byType[payload.type].byId[payload.id].list, ...payload.artworks],
              currentPage: payload.currentPage,
              endOfResults: payload.endOfResults,
              isFetching: false,
            },
          },
        },
      },
    }),
    [CHANGE_CURRENT_LISTING]: (state, { payload }) => ({
      ...state,
      currentListing: payload.id,
    }),
    [RECEIVE_ARTWORK]: (state, { payload }) => ({
      ...state,
      byId: {
        ...state.byId,
        [payload.artwork.id]: payload.artwork,
      },
      byType: {
        ...state.byType,
        [payload.type]: {
          ...state.byType[payload.type],
          byId: {
            ...state.byType[payload.type].byId,
            [payload.id]: {
              ...state.byType[payload.type].byId[payload.id],
              list: state.byType[payload.type].byId[payload.id].list.map((artwork) =>
                payload?.artwork && artwork.id === payload.artwork.id
                  ? { ...payload.artwork, isFull: true }
                  : artwork,
              ),
            },
          },
        },
      },
    }),
  },
  defaultState,
);
