import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

type Props = {
  optionsElement: (cb: Dispatch<SetStateAction<boolean>>) => React.ReactElement;
  buttonElement: () => React.ReactElement;
};

export const ControlWithOptions: FC<Props> = ({ optionsElement, buttonElement }) => {
  const [isShown, setIsShown] = useState<boolean>(false);

  return (
    <div
      style={{ display: 'inline-block', position: 'relative' }}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >
      <CSSTransition in={isShown} timeout={100} classNames='css-transition--opacity' mountOnEnter>
        {optionsElement(setIsShown)}
      </CSSTransition>
      {buttonElement()}
    </div>
  );
};
