import { ActionType } from 'typesafe-actions';

import keyBy from 'lodash/keyBy';

import * as Types from 'types';
import update from 'immutability-helper';

import { PlaylistsState, PlaylistsActionTypes } from './types';

const initialState: PlaylistsState = {
  currentActiveItemId: undefined,
  activePlaylistId: undefined,
  isFetching: false,
  isAddArtworkOpen: false,
  current: undefined,
  byId: {},
  artStreamArtworksFilter: '',
  artStreamArtworksFetching: false,
};

/**
 * 1. Removes _meta key from playlist_artworks which may contain
 *    objects (eg. getBoundingClientRect) :/ Alternatively
 *    we could filter this somewhere else, but maybe this is the best place
 *    since it prevents us from polluting state.
 *
 * 2. Casts playlist.id and playlistArtwork.id into string.
 *    Front-end users strings as ids, back-end uses int.
 *    Here we unify type to avoid comparison issues (5 != '5').
 */
const sanitizePlaylist = (playlist: Types.Playlist) =>
  update(playlist, {
    id: { $apply: (id: string) => String(id) },
    items: {
      $apply: (playlistArtworks: any) =>
        playlistArtworks.map((playlistArtwork: any) =>
          update(playlistArtwork, {
            id: { $apply: (id: string) => String(id) },
            $unset: ['_meta'],
          }),
        ),
    },
  });

import { Actions } from './actions';
export type PlaylistsAction = ActionType<typeof Actions>;

export const playlistsReducer = (state = initialState, action: PlaylistsAction): PlaylistsState => {
  switch (action.type) {
    case PlaylistsActionTypes.START_FETCHING:
      return {
        ...state,
        isFetching: true,
      };

    case PlaylistsActionTypes.SET_CURRENT_PLAYLIST:
      return {
        ...state,
        current: action.payload && sanitizePlaylist(action.payload),
      };

    case PlaylistsActionTypes.SET_PLAYLISTS:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...keyBy(
            action.payload.map((playlist) => sanitizePlaylist(playlist)),
            'id',
          ),
        },
      };

    case PlaylistsActionTypes.RESET_PLAYLISTS:
      return {
        ...state,
        byId: keyBy(action.payload, 'id'),
      };

    case PlaylistsActionTypes.REMOVE_PLAYLIST:
      const byId = Object.assign({}, state.byId);
      delete byId[action.payload];

      return {
        ...state,
        byId,
      };

    case PlaylistsActionTypes.RECEIVE_PLAYLISTS:
      const playlists = action.payload
        .map(sanitizePlaylist)
        .reduce((acc, playlist) => ({ ...acc, [playlist.id]: playlist }), {});

      return {
        ...state,
        byId: {
          ...state.byId,
          ...playlists,
        },
        isFetching: false,
      };

    case PlaylistsActionTypes.SET_ADD_ARTWORK_OPEN:
      return {
        ...state,
        isAddArtworkOpen: action.payload,
      };

    case PlaylistsActionTypes.SET_ART_STREAM_ARTWORKS_FILTER:
      return {
        ...state,
        artStreamArtworksFilter: action.payload,
      };

    case PlaylistsActionTypes.SET_ART_STREAM_ARTWORKS_FETCHING:
      return {
        ...state,
        artStreamArtworksFetching: action.payload,
      };

    default:
      return state;
  }
};
