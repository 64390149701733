import React, { FC } from "react";

import { Center } from 'components/utils/Center';
import { Spinner } from 'components/Spinner';
import { useTimeout } from "hooks/useTimeout";

export const Loader: FC = () => {
  const isOver = useTimeout(500);

  return (
    <>
      {isOver && (
        <Center>
          <Spinner size='m' light />
        </Center>
      )}
    </>
  )
}