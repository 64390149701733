import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Modal from 'react-modal';

import { store } from 'apps/homepage/store';
import { AppContainer } from 'apps/homepage/components/app/Container';

import { UserProvider } from 'hooks/useCurrentUser';

const container = document.getElementById('artwork-grid');

if (container) {
  Modal.setAppElement(container);

  ReactDOM.render(
    <Provider store={store}>
      <UserProvider>
        <AppContainer />
      </UserProvider>
    </Provider>,
    container,
  );
}
