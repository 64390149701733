import React from 'react';
import posed from 'react-pose';

const config = {
  visible: { opacity: 1, transition: { duration: 500 } },
  hidden: { opacity: 0 },
};

const AnimationWrapper = posed.div(config);

type Props = {
  className?: string;
};

export const AnimatedCard: React.FC<Props> = (props) => {
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <AnimationWrapper
      className={props.className}
      pose={isVisible ? 'visible' : 'hidden'}
    >
      {props.children}
    </AnimationWrapper>
  );
};
