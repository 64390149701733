import React, { useContext } from 'react';

import * as Types from 'types';

export interface UserType {
  readonly user?: Types.LoggedInUser;
}

const defaultValue = {
  user: undefined,
};

export const User = React.createContext<UserType>(defaultValue);

export const UserProvider: React.FC<UserType> = ({ user, ...props }) => (
  <User.Provider
    {...props}
    value={{
      user: sedition.loggedInUser,
    }}
  />
);

export const useCurrentUser = (): Types.LoggedInUser | undefined => {
  const context = useContext(User);
  if (context === undefined) {
    throw new TypeError('useUser must be used within a UserProvider');
  }
  return context.user;
};
