import React, { FC } from 'react';

type Props = {
  children: React.ReactNode,
};

export const Center: FC<Props> = ({ children }: Props) => (
  <div
    style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    }}
  >
    {children}
  </div>
);
