import React from 'react';

import { ArtworkDescription } from 'components/artworkPreviewModal/ArtworkDescription';

type Props = {
  title: string;
  url: string;
  description?: string;
};

export const ArtworkInfo: React.FC<Props> = ({ title, description, url }) => (
  <div>
    <a className='artwork-header' href={url}>
      <h1 className='artwork-header__title artwork-header__title--lg mb-1'>{title}</h1>
    </a>
    {description && <ArtworkDescription description={description} />}
  </div>
);
