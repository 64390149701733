/* --------------------------------------------------------------------------
   Helpers associated with Google Analytics tracking
   -------------------------------------------------------------------------- */

const getUTMParameters = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const utmSource = searchParams.get('utm_source');
  const utmMedium = searchParams.get('utm_medium');
  const utmCampaign = searchParams.get('utm_campaign');
  const utmContent = searchParams.get('utm_content');

  return { source: utmSource, medium: utmMedium, campaign: utmCampaign, content: utmContent };
};

const getSource = (): string => {
  // document.referrer may not always be available, as some browsers block it for security reasons.
  if (window.location.search.includes('utm_source')) {
    const { source, medium, campaign, content } = getUTMParameters();
    return `source: ${source}, medium: ${medium}, campaign: ${campaign} content: ${content}`;
  }

  const referrer = document.referrer;
  if (referrer) return `source: ${referrer}`;

  // we treat anything else as direct traffic
  // https://support.google.com/analytics/answer/6205762?hl=en#zippy=%2Cin-this-article
  return 'source: direct';
};

const onClickTracking = (eventName: string, eventCategory: string, eventLabel: string): void => {
  // type guard to ensure the that the function is not undefined before calling it
  if (gtag) {
    gtag('event', eventName, {
      event_category: eventCategory,
      event_label: eventLabel,
    });
  }
};
export { getSource, onClickTracking };
