import React, { FC, useEffect, useRef } from 'react';

import * as Types from 'types';

import { ModalControls } from 'components/artworkPreviewModal/ModalControls';
import { Header } from 'components/artworks/ArtworkPreviewModal/Header';
import { ArtworkInfo } from 'components/artworkPreviewModal/ArtworkInfo';
import { ArtworkPriceInfo } from 'components/artworkPreviewModal/ArtworkPriceInfo';

import { ArtworkPlayer } from 'artworkPlayer';
import { mapLegacyArtworkToArtwork } from 'features/artworks/api';
import { Modal } from 'components/modal';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { isArtworkAvailableViaArtStream } from 'artworks/helpers';
import { AddToPlaylistWidget } from 'playlists/AddToPlaylistWidget';
import { Currency, formatArtworkPrice } from 'services/stringFormating';

import { ModalData } from './Container';
import { Action } from 'typesafe-actions';

type StateProps = {
  isOpen: boolean;
  artwork: Types.LegacyArtwork | undefined;
  prevArtworkId: number | null;
  nextArtworkId: number | null;
  currency: Currency | null;
  currentModalData: ModalData | null;
};

type DispatchProps = {
  onClose: () => Action<any>;
  onChangeArtwork: (data: { artworkId: number }) => void;
};

export type OwnProps = {
  afterOpenModal: (currentModalData: ModalData, artwork: Types.LegacyArtwork) => void;
  modalId: string;
};

type Props = OwnProps & StateProps & DispatchProps;

export const HomepageArtworkPreviewModal: FC<Props> = ({
  artwork: legacyArtwork,
  afterOpenModal,
  currentModalData,
  prevArtworkId,
  nextArtworkId,
  onChangeArtwork,
  isOpen,
  onClose,
}) => {
  const artwork = legacyArtwork && mapLegacyArtworkToArtwork(legacyArtwork);
  const modalRef = useRef<HTMLDivElement>(null);
  const user = useCurrentUser();

  const onClickArrow = ({ keyCode }: KeyboardEvent) => {
    if (keyCode === Types.KEYS.ARROW_LEFT && prevArtworkId) {
      onChangeArtwork({
        ...currentModalData,
        artworkId: prevArtworkId,
      });
    } else if (keyCode === Types.KEYS.ARROW_RIGHT && nextArtworkId) {
      onChangeArtwork({
        ...currentModalData,
        artworkId: nextArtworkId,
      });
    }
  };

  const afterOpenModalHandler = () => {
    artwork && currentModalData && legacyArtwork && afterOpenModal(currentModalData, legacyArtwork);

    document.addEventListener('keydown', onClickArrow);
  };

  const requestClose = () => {
    document.removeEventListener('keydown', onClickArrow);
    onClose();
  };

  useEffect(afterOpenModalHandler, [artwork?.id]);

  return (
    <div className='pos-rel'>
      <Modal
        variant='artwork-preview'
        isOpen={isOpen}
        onRequestClose={requestClose}
        overlayClassName='artwork-preview__modal-overlay'
        onAfterOpen={afterOpenModalHandler}
      >
        {artwork && (
          <div ref={modalRef}>
            <Header
              userId={artwork.artistUserId}
              name={artwork.artistName}
              url={artwork.artistUrl}
              avatarUrl={legacyArtwork?.artist_avatar_url || ''}
            />
            <div className='pos-rel'>
              <ArtworkPlayer artwork={artwork} options={{ autoplay: true }} />
            </div>
            <div className='container-fluid my-1 my-lg-2 px-lg-2'>
              <div className='row'>
                <div className='col-12 col-sm'>
                  <ArtworkInfo
                    title={artwork.title}
                    description={
                      artwork.id && legacyArtwork?.description ? legacyArtwork?.description : ''
                    }
                    url={artwork.url}
                  />
                </div>
                <div className='col-12 col-sm-auto'>
                  {user?.artStreamActive && isArtworkAvailableViaArtStream(artwork) ? (
                    <div className='artwork-actions'>
                      <AddToPlaylistWidget artwork={artwork} modalRef={modalRef} />
                    </div>
                  ) : (
                    <ArtworkPriceInfo
                      id={artwork.id}
                      version={'artwork.version'}
                      soldOut={Boolean(artwork.soldOut)}
                      price={formatArtworkPrice(artwork.price)}
                      totalEditions={artwork.totalEditions || 0}
                      availableForPurchase={artwork.availableForPurchase}
                      forSale={Boolean(artwork.forSale)}
                      unavailableEditionsCount={
                        artwork.totalEditions
                          ? artwork.totalEditions - artwork.remainingEditions
                          : 0
                      }
                      auctionState={artwork.auctionState}
                      isNFT={artwork.nft}
                    />
                  )}
                  <a className='btn btn--lg btn--border mt-1 w-100' href={artwork.url}>
                    More Details
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        <ModalControls
          prevArtworkId={prevArtworkId}
          nextArtworkId={nextArtworkId}
          onChangeArtwork={onChangeArtwork}
        />
      </Modal>
    </div>
  );
};
