import { request } from 'utils/request';

import * as Types from 'types';

export type ResponseMedia = {
  artwork_id: number;
  url: string;
  expires_at?: number | string;
};

export const mapResponseMediaToMedia = (media: ResponseMedia): Types.Media => ({
  artworkId: media.artwork_id,
  url: media.url,
  expiresAt: media.expires_at ? parseInt(String(media.expires_at), 10) : null,
});

export const requestPreviewMediaById = (
  artworkId: number,
): Promise<Types.Media> =>
  request
    .get<ResponseMedia>(
      `/api/internal/artworks/${artworkId}/media.json?file_format=adaptive_preview`,
    )
    .then(({ data }) => mapResponseMediaToMedia(data));
