import React from 'react';
import ContentLoader from 'react-content-loader';

const ARTWORK_TITLE_AVG_HEIGHT = 40; // sync with @artwork-header-min-height

type Props = {
  id?: number;
  className?: string;
};

export const ArtworkPlaceholder: React.FC<Props> = ({ id, className = '' }) => (
  <div className={className} {...(id ? { 'data-id': id } : {})}>
    <div
      className='keep-aspect-ratio mb-1'
      style={{ marginBottom: ARTWORK_TITLE_AVG_HEIGHT }}
    >
      <div className='keep-aspect-ratio__content'>
        <ContentLoader
          width={400}
          height={225}
          speed={2}
          foregroundColor='#f3f3f3'
          backgroundColor='#ecebeb'
          className='stretch w-100 h-100'
          viewBox='0 0 400 225'
        >
          <rect x='0' y='0' rx='5' ry='5' width='400' height='225' />
        </ContentLoader>
      </div>
    </div>
    <div
      className='pos-rel'
      style={{
        height: `${ARTWORK_TITLE_AVG_HEIGHT}px`,
      }}
    >
      <ContentLoader
        width={400}
        height={ARTWORK_TITLE_AVG_HEIGHT}
        speed={2}
        foregroundColor='#f3f3f3'
        backgroundColor='#ecebeb'
        className='stretch w-100 h-100'
        viewBox={`0 0 400 ${ARTWORK_TITLE_AVG_HEIGHT}`}
        preserveAspectRatio='xMinYMid meet'
      >
        <rect x='0' y='0' rx='5' ry='5' width='180' height='16' />
        <rect x='0' y='24' rx='5' ry='5' width='120' height='16' />
      </ContentLoader>
      <div
        className='pos-abs stretch'
        style={{
          height: `${ARTWORK_TITLE_AVG_HEIGHT}px`,
        }}
      >
        <ContentLoader
          width={400}
          height={ARTWORK_TITLE_AVG_HEIGHT}
          speed={2}
          foregroundColor='#f3f3f3'
          backgroundColor='#ecebeb'
          className='stretch w-100 h-100'
          viewBox={`0 0 400 ${ARTWORK_TITLE_AVG_HEIGHT}`}
          preserveAspectRatio='xMaxYMid meet'
        >
          <rect x='370' y='0' rx='5' ry='5' width='30' height='16' />
        </ContentLoader>
      </div>
    </div>
  </div>
);
